<template>
    <div class="ml-6 mt-1 flex flex-col items-start space-x-2 border-b-2 py-2">
        <div class="flex space-x-2 w-full pl-2">
            <div class="text-sm font-bold">
                {{ recipientDisplay(recipient) }}
            </div>
            <div></div>
        </div>
        <div class="flex space-x-2">
            <img
                :src="
                    recipient.photo_url
                        ? recipient.photo_url
                        : `https://eu.ui-avatars.com/api/?name=${recipientDisplay(recipient)}&size=80`
                "
                class="profile-pic"
            />
            <div class="flex flex-col">
                <span v-if="recipient.display_title" class="text-sm">{{
                    recipient.display_title
                }}</span>
                <span v-if="recipient.party_name" class="text-xs text-gray-500"
                    >{{ recipient.party_name }} ({{
                        recipient.party_short_name
                    }})</span
                >
                <span
                    v-if="recipient.email"
                    class="text-xs text-gray-500 flex items-center"
                >
                    <i class="fi fi-rs-envelope mr-1 translate-y-0.5"></i>
                    {{ recipient.email }}
                </span>
                <span
                    v-if="recipient.twitter_handle"
                    class="text-xs text-gray-500 flex items-center"
                >
                    <i
                        class="fi fi-brands-twitter-alt-circle mr-1 translate-y-0.5"
                    ></i>
                    {{ recipient.twitter_handle }}
                </span>
                <span
                    v-if="recipient.facebook_page"
                    class="text-xs text-gray-500 flex items-center"
                >
                    <i class="fi fi-brands-facebook mr-1 translate-y-0.5"></i>
                    {{ recipient.facebook_page }}
                </span>
                <span
                    v-if="recipient.instagram_handle"
                    class="text-xs text-gray-500 flex items-center"
                >
                    <i class="fi fi-brands-facebook mr-1 translate-y-0.5"></i>
                    {{ recipient.instagram_handle }}
                </span>
                <span
                    v-if="!contactInfosAvailable"
                    class="text-xs text-warning flex items-center"
                >
                    {{ t('user.campaign.recipient_no_contact_info') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { recipientDisplay, RecipientSimple } from '@/models/recipients'
import { useI18n } from 'vue-i18n'

defineProps<{
    recipient: RecipientSimple
    contactInfosAvailable: boolean
}>()

const { t } = useI18n()
</script>

<style scoped>
.profile-pic {
    width: 80px;
    height: 80px;
    border-radius: 10%;
}
</style>
