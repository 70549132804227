import { LocationQuery, RouteLocationNormalized } from 'vue-router'
import isEqual from 'lodash/isEqual'
import { LocationQueryRaw } from 'vue-router'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'

export const routeToListViewProps = (route: RouteLocationNormalized) => ({
    page: typeof route.query.page === 'string' ? parseInt(route.query.page) : 1,
    filter:
        typeof route.query.filter === 'string' ? route.query.filter : undefined,
})

/**
 * Propagate query used by a previous route to a new route.
 * Useful e.g to save the state of the list when navigating to a modal view.
 */
export const propagatePreviousRouteQuery = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized
) => {
    const toQuery = _flattenQueryValues(to.query)
    Object.assign(toQuery, _flattenQueryValues(from.query))
    if (!isEqual(toQuery, to.query)) {
        return {
            path: to.path,
            query: toQuery as LocationQueryRaw,
        }
    }
}

const _flattenQueryValues = (query: LocationQuery): { [k: string]: string } =>
    pickBy(
        mapValues(query, _flattenQueryValue),
        (v): v is string => typeof v === 'string'
    )

const _flattenQueryValue = (queryValue: LocationQuery[keyof LocationQuery]) => {
    if (Array.isArray(queryValue)) {
        if (typeof queryValue[0] === 'string') {
            return queryValue[0]
        } else {
            return undefined
        }
    } else {
        return queryValue
    }
}
