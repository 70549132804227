<template>
    <div class="pb-12 md:pb-0 flex flex-col lg:justify-center md:h-auto w-full">
        <div
            v-if="wizardStore.currentStepIndex == 0"
            v-html="store.campaign!.description || ''"
            class="mb-4"
        ></div>
        <slot></slot>
    </div>
</template>
<script setup lang="ts">
import {
    useSendCampaignStore,
    useSendCampaignWizardStore,
} from '@/AppCampaigns/stores'

interface Props {
    campaignId: string | null
}
const props = defineProps<Props>()

const store = useSendCampaignStore(props.campaignId)
const wizardStore = useSendCampaignWizardStore(props.campaignId)
</script>
