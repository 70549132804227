<template>
    <span class="relative group">
        <span
            class="inline-flex items-center justify-center w-3 h-3 bg-slate-500 text-white text-xs rounded-full cursor-pointer"
            >i</span
        >
        <AVTooltip :message="props.message" />
    </span>
</template>
<script setup lang="ts">
import AVTooltip from './AVTooltip.vue'

interface Props {
    message: string
}

const props = defineProps<Props>()
</script>
