<template>
    <ContainerMessage>
        <div class="recipients-list pb-2 text-ellipsis">
            <div class="recipients-label">
                {{ t('user.email.recipients') }} :
            </div>
            <ButtonCopy
                v-if="!isStreaming"
                :content-to-copy="
                    sendTo.map((recipient) => recipient.email).join(', ')
                "
            />
            <span
                class="recipient-email"
                v-for="recipient in sendTo"
                :key="recipient.email"
            >
                {{ recipient.email }}</span
            >
        </div>

        <div class="cc-list pb-2" v-if="ccList && ccList.length > 0">
            <div class="recipients-label">Cc :</div>
            <ButtonCopy
                v-if="!isStreaming"
                :content-to-copy="
                    ccList.map((recipient) => recipient.email).join(', ')
                "
            />
            <span
                class="text-[12px] recipient-email"
                v-for="recipient in ccList"
                :key="recipient.email"
            >
                {{ recipient.email }}</span
            >
        </div>

        <div class="subject pb-2 border-b border-gray-200 mb-2">
            <div class="subject-label">{{ t('user.email.subject') }} :</div>
            {{ subject }}
            <CursorFlashing v-if="isStreaming && body.length === 0" />
            <ButtonCopy v-if="!isStreaming" :content-to-copy="subject" />
        </div>

        <div class="content">
            <div class="content-headline">
                <div class="content-label">{{ t('user.email.message') }} :</div>
            </div>
            <ButtonCopy
                v-if="!isStreaming"
                :content-to-copy="bodyAndSignature"
            />
            <div>
                {{ body }}
                <CursorFlashing v-if="isStreaming && body.length > 0" />
                <div v-if="signature"><br />{{ signature }}</div>
            </div>
        </div>
    </ContainerMessage>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ContainerMessage from '../ContainerMessage.vue'
import CursorFlashing from '../CursorFlashing.vue'
import ButtonCopy from './ButtonCopy.vue'
import { RecipientPublic } from '@/models/recipients'
import { combineBodyAndSignature } from './utils'
import { useI18n } from 'vue-i18n'

interface Props {
    sendTo: Array<RecipientPublic>
    ccList: Array<RecipientPublic>
    subject: string
    body: string
    signature: string
    isStreaming: boolean
}

const props = defineProps<Props>()

const { t } = useI18n()

const bodyAndSignature = computed(() =>
    combineBodyAndSignature(props.body, props.signature)
)
</script>
<style scoped>
.recipient-email::after {
    content: ', ';
}

.recipient-email:last-child::after {
    content: '';
}

.recipients-label,
.subject-label,
.content-label {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 3px;
}

.label-hint {
    color: var(--color-dark-gray);
    font-size: 13px;
    font-weight: 300;
}

.subject {
    display: block;
    position: relative;
}

.content {
    display: block;
    font-weight: normal;
    line-height: 1.2rem;
    position: relative;
    color: var(--color-grey-900);
}

.recipients-list,
.cc-list {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    position: relative;
    max-width: 100%;
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .recipients-label,
    .subject-label,
    .content-label,
    .subject,
    .content {
        line-height: 1.3em;
    }
}
</style>
