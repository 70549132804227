<template>
    <div
        :class="`bg-white ${
            props.buttonsAlign === 'center' ? 'justify-center' : 'justify-end'
        }`"
    >
        <div
            :class="`flex space-x-2 ${
                props.buttonsAlign === 'center'
                    ? 'justify-center'
                    : 'justify-end'
            }`"
        >
            <AVButton
                button-type="outline-primary"
                @click="onCancelClicked"
                :disabled="props.disabled"
            >
                {{ t(cancelTKey) }}
            </AVButton>
            <AVButton
                :type="props.okButtonType"
                @click="onOkClicked"
                :disabled="props.disabled"
            >
                {{ t(okTKey) }}
            </AVButton>
        </div>
    </div>
</template>
<script setup lang="ts">
import { TKey } from '@/i18n'
import { RouteName } from '@/types'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import AVButton from '@/components/forms/AVButton.vue'

interface Props {
    okTKey: TKey
    routeNameOnClose: RouteName
    buttonsAlign?: 'center' | 'side'
    okButtonType?: 'button' | 'submit'
    cancelTKey?: TKey
    disabled: boolean
}

interface Emits {
    (event: 'ok'): void
}

const props = withDefaults(defineProps<Props>(), {
    buttonsAlign: 'side',
    okButtonType: 'button',
    cancelTKey: 'backoffice.actions.cancel',
    disabled: false,
})
const emit = defineEmits<Emits>()
const { t } = useI18n()
const router = useRouter()

const onOkClicked = () => {
    // If the button type is submit, the form will handle the submit event
    if (props.okButtonType !== 'submit') {
        emit('ok')
    }
}

const onCancelClicked = () => {
    router.push({
        name: props.routeNameOnClose,
    })
}
</script>
<style scoped>
.btn {
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    font-weight: 600;
}

.btn:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
</style>
@/index
