<template>
    <ContainerMain class="lg:text-center">
        <EmailPreview
            v-if="generatedEmail"
            class="w-full text-left"
            :send-to="sendTo"
            :cc-list="ccList"
            :subject="generatedEmail.subject"
            :body="generatedEmail.body"
            :signature="emailSignature"
            :is-streaming="!store.isStreamingIdle"
        />
        <ButtonGenerateOther
            v-if="store.isStreamingIdle"
            label-t-key="user.campaign.comment_generate_other_email"
            @generate-message="store.reformulateContentSample()"
        />
        <TransitionExpands>
            <div class="mt-5" v-if="store.isStreamingIdle">
                <div class="courtesy-warning text-[13px] font-medium mb-5">
                    {{ t('user.email.courtesy_warning') }}
                </div>
                <SendEmailButton
                    v-if="generatedEmail"
                    class="mb-4"
                    :send-to="sendTo"
                    :cc-list="ccList"
                    :bcc-list="bccList"
                    :subject="generatedEmail.subject"
                    :body="generatedEmail.body"
                    :signature="emailSignature"
                    @send-button-clicked="() => emit('sendButtonClicked')"
                />
                <!-- <div
                    class="courtesy-warning mt-8 text-[13px] font-medium block lg:hidden"
                >
                    {{ t('user.email.courtesy_warning') }}
                </div> -->
            </div>
        </TransitionExpands>
    </ContainerMain>
</template>
<script setup lang="ts">
import EmailPreview from './EmailPreview.vue'
import SendEmailButton from './SendEmailButton.vue'
import ContainerMain from '../ContainerMain.vue'
import TransitionExpands from '../TransitionExpands.vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSendCampaignStore } from '@/AppCampaigns/stores'
import {
    SendButtonsEmits,
    StreamingStatus,
} from '@/AppCampaigns/SendCampaign/types'
import { ContentSampleEmail } from '@/models/content-samples'
import shuffle from 'lodash/shuffle'
import { BCC_DOMAIN } from '@/constants'
import ButtonGenerateOther from '../GenerateCommentOrTweet/ButtonGenerateOther.vue'

interface Emits extends SendButtonsEmits {}

const emit = defineEmits<Emits>()

interface Props {
    campaignId: string | null
}
const props = defineProps<Props>()

const { t } = useI18n()
const store = useSendCampaignStore(props.campaignId)

const sendTo = computed(() =>
    store.recipient
        ? [store.recipient]
        : shuffle(store.recipients!).slice(0, 20)
)

const ccList = computed(() =>
    shuffle(store.interpellation!.cc_list || []).slice(0, 20)
)

const bccList = computed(() => {
    const baseBCC = shuffle(store.interpellation!.bcc_list || [])
        .slice(0, 20)
        .map((bcc) => bcc.email)
    if (store.generatedContentId && store.campaign?.is_tracking_sent_emails) {
        return [...baseBCC, `${store.generatedContentId}@${BCC_DOMAIN}`]
    }
    return baseBCC
})

const generatedEmail = computed(
    () => store.generatedContent as ContentSampleEmail | null
)

const emailSignature = computed(() => {
    if (store.streamStatus == StreamingStatus.IN_PROGRESS) {
        return ''
    }

    if (store.sender && (store.sender.firstName || store.sender.lastName)) {
        return `${store.sender.firstName || ''} ${store.sender.lastName || ''}`.trim()
    } else if (store.isStreamingIdle) {
        return ''
    } else {
        return '\n\nPrénom Nom'
    }
})
</script>
