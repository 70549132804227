import {
    ContentSampleComment,
    ContentSampleEmail,
    ContentSampleTweet,
} from '@/models/content-samples'

export enum StreamingStatus {
    INIT = 0,
    STARTING = 1,
    IN_PROGRESS = 2,
    SUCCESS = 3,
    ERROR = 4,
    ABORTED = 5,
}

export interface Sender {
    firstName: string
    lastName: string
}

export type GeneratedEmailStreamData = Partial<Omit<ContentSampleEmail, 'type'>>
export type GeneratedCommentStreamData = Partial<
    Omit<ContentSampleComment, 'type'>
>
export type GeneratedTweetStreamData = Partial<Omit<ContentSampleTweet, 'type'>>

export type GeneratedContentStream =
    | GeneratedEmailStreamData
    | GeneratedCommentStreamData
    | GeneratedTweetStreamData

export interface SendButtonsEmits {
    (event: 'sendButtonClicked'): void
}
