import { PLACEHOLDER_PATTERN } from '@/constants'
import { TKey } from '@/i18n/index'
import { components } from '@/schema'

import * as Yup from 'yup'
import { assertTypesMatch, TypeEqualityGuard } from '@/utils/types'

export const QUESTION_TYPE_OPTIONS: Array<{
    label: TKey
    value: QuestionType
}> = [
    { label: 'backoffice.customQuestions.textType', value: 'text' },
    { label: 'backoffice.customQuestions.selectType', value: 'select' },
]

type CustomQuestionShared = components['schemas']['CustomQuestionShared']

export type CustomQuestionSelectOption =
    components['schemas']['CustomQuestionSelectOption']

export type CustomQuestionSelect = CustomQuestionShared & {
    type: 'select'
    options: {
        answer: string
        prompt: string
    }[]
}

export type CustomQuestionText = CustomQuestionShared & {
    type: 'text'
} & components['schemas']['CustomQuestionText']

export type CustomQuestion = CustomQuestionSelect | CustomQuestionText

assertTypesMatch<
    TypeEqualityGuard<
        components['schemas']['CustomQuestionCustomQuestionSelect'],
        Omit<CustomQuestionSelect, 'type'> & { type: 'select' | 'text' }
    >
>()
assertTypesMatch<
    TypeEqualityGuard<
        components['schemas']['CustomQuestionCustomQuestionText'],
        Omit<CustomQuestionText, 'type'> & { type: 'select' | 'text' }
    >
>()

export type QuestionType = components['schemas']['CustomQuestionSharedTypeEnum']

const customQuestionValidationBase = {
    question: Yup.string().required(),
    required: Yup.boolean().required(),
    placeholder: Yup.string().required(),
}

const customQuestionTextValidator: Yup.ObjectSchema<CustomQuestionText> =
    Yup.object().shape({
        ...customQuestionValidationBase,
        type: Yup.string().oneOf(['text']).required(),
        prompt: Yup.string()
            .matches(
                PLACEHOLDER_PATTERN,
                'This field must contain the placeholder ***PLACEHOLDER***'
            )
            .required(),
    })

const customQuestionSelectOptionValidator: Yup.ObjectSchema<CustomQuestionSelectOption> =
    Yup.object().shape({
        answer: Yup.string().required(),
        prompt: Yup.string().required(),
    })

const customQuestionSelectValidator: Yup.ObjectSchema<CustomQuestionSelect> =
    Yup.object().shape({
        ...customQuestionValidationBase,
        type: Yup.string().oneOf(['select']).required(),
        options: Yup.array().of(customQuestionSelectOptionValidator).required(),
    })

export const customQuestionValidator = Yup.lazy((value: CustomQuestion) => {
    if (value.type === 'text') {
        return customQuestionTextValidator
    } else if (value.type === 'select') {
        return customQuestionSelectValidator
    } else {
        throw new Error(`Unknown custom question type: ${value['type']}`)
    }
})
