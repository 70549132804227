<template>
    <div class="relative w-full h-full bg-grey-500 overflow-auto">
        <Transition name="reenter-appear">
            <!-- Reenter screen -->
            <SendCampaignContainer v-if="store.isSendDone">
                <SendCampaignInnerContainer>
                    <ProgressBarMobile
                        class="block md:hidden"
                        :has-previous-button="true"
                        title-t-key="user.email.StepReenterProgressBarTitle"
                        @previous="onMobilePreviousClicked"
                    >
                        <template #circle>
                            <CircleProgress progress="FULL" label="✓" />
                        </template>
                    </ProgressBarMobile>
                </SendCampaignInnerContainer>

                <SendCampaignInnerContainer class="pt-8 md:pb-8">
                    <ReenterScreen :campaign-id="campaign.id!" />
                </SendCampaignInnerContainer>
            </SendCampaignContainer>

            <!-- All steps -->
            <SendCampaignContainer v-else>
                <SendCampaignInnerContainer>
                    <ProgressBar
                        v-if="wizardStore.selectedStepsDefinitions.length > 0"
                        :current-step-index="wizardStore.currentStepIndex"
                        :selected-steps-definitions="
                            wizardStore.selectedStepsDefinitions
                        "
                        :can-go-forward="wizardStore.canGoForward"
                        :can-go-backward="wizardStore.canGoBackward"
                        @change-current-step="wizardStore.goToStep"
                    />
                </SendCampaignInnerContainer>
                <SendCampaignInnerContainer
                    v-if="isInitialized"
                    class="relative h-max-full flex-1 flex flex-col"
                >
                    <div
                        ref="scrollContainer"
                        class="flex-1 pt-6 md:pt-16 overflow-auto"
                    >
                        <div
                            v-if="campaign && campaign.state === 'active'"
                            class="relative h-full block"
                        >
                            <Transition
                                :name="stepTransitionName"
                                class="h-full block px-4"
                            >
                                <StepInterpellationSelection
                                    v-if="
                                        wizardStore.currentStepKey ===
                                        'StepInterpellationSelection'
                                    "
                                    :campaign-id="campaign.id!"
                                />
                                <StepChoosePlace
                                    v-else-if="
                                        wizardStore.currentStepKey ===
                                        'StepChoosePlace'
                                    "
                                    :campaign-id="campaign.id!"
                                />
                                <StepGeolocation
                                    v-else-if="
                                        wizardStore.currentStepKey ===
                                        'StepGeolocation'
                                    "
                                    :campaign-id="campaign.id!"
                                />
                                <StepInterpellationModeSelection
                                    v-else-if="
                                        wizardStore.currentStepKey ===
                                        'StepInterpellationModeSelection'
                                    "
                                    :campaign-id="campaign.id!"
                                />
                                <StepSenderQuestions
                                    v-else-if="
                                        wizardStore.currentStepKey ===
                                        'StepSenderQuestions'
                                    "
                                    :campaign-id="campaign.id!"
                                />
                                <StepGenerateMessage
                                    v-else-if="
                                        wizardStore.currentStepKey ===
                                        'StepGenerateMessage'
                                    "
                                    :campaign-id="campaign.id!"
                                />
                            </Transition>
                        </div>
                        <div v-else>
                            <p
                                id="campaign-deactivation-reason"
                                v-html="
                                    [
                                        '',
                                        '<p></p>',
                                        undefined,
                                        '<p><br></p>',
                                    ].includes(
                                        store.campaign?.deactivation_reason
                                    )
                                        ? t('user.campaign.ended')
                                        : store.campaign?.deactivation_reason
                                "
                            ></p>
                        </div>
                    </div>
                </SendCampaignInnerContainer>
            </SendCampaignContainer>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import SendCampaignContainer from '@/components/containers/SendCampaignContainer.vue'
import SendCampaignInnerContainer from '@/components/containers/SendCampaignInnerContainer.vue'
import ReenterScreen from './ReenterScreen/ReenterScreen.vue'
import ProgressBarMobile from '@/components/ProgressBar/ProgressBarMobile.vue'
import CircleProgress from '@/components/ProgressBar/CircleProgress.vue'
import StepInterpellationSelection from './StepInterpellationSelection/StepInterpellationSelection.vue'
import StepGeolocation from './StepGeolocation/StepGeolocation.vue'
import StepChoosePlace from './StepChoosePlace/StepChoosePlace.vue'
import StepInterpellationModeSelection from './StepInterpellationModeSelection/StepInterpellationModeSelection.vue'
import StepSenderQuestions from './StepSenderQuestions/StepSenderQuestions.vue'
import StepGenerateMessage from './StepGenerateMessage/StepGenerateMessage.vue'
import {
    useSendCampaignStore,
    useSendCampaignWizardStore,
} from '@/AppCampaigns/stores'
import { onMounted, ref, watch } from 'vue'
import { CampaignPublic } from '@/models/campaigns'
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue'

export interface Props {
    campaign: CampaignPublic
}

const props = defineProps<Props>()

const isInitialized = ref(false)
const scrollContainer = ref<HTMLDivElement | null>(null)
const stepTransitionName = ref<'next-step' | 'previous-step'>('next-step')
const { locale, t } = useI18n()
const store = useSendCampaignStore(props.campaign.id)
const wizardStore = useSendCampaignWizardStore(props.campaign.id)

store.$onAction(async ({ name }) => {
    if (name === 'setSender') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
})

watch(
    () => wizardStore.currentStepIndex,
    (newIndex, oldIndex) => {
        stepTransitionName.value =
            newIndex > oldIndex ? 'next-step' : 'previous-step'
        if (scrollContainer.value && newIndex !== oldIndex) {
            scrollContainer.value.scrollTop = 0
        }
    }
)

const onMobilePreviousClicked = () => {
    store.setIsSendDone(false)
}

onMounted(() => {
    locale.value = props.campaign.language || 'en'
    store.$reset()
    store.setCampaign(props.campaign)
    isInitialized.value = true
})
</script>

<style scoped>
.previous-step-enter-active,
.previous-step-leave-active,
.next-step-enter-active,
.next-step-leave-active {
    transition:
        transform 350ms linear,
        opacity 350ms linear;
    position: absolute;
    top: 0;
}

.next-step-leave-from,
.previous-step-leave-from {
    transform: translateX(0);
    opacity: 1;
}

.previous-step-enter-from,
.next-step-leave-active {
    transform: translateX(-100vw);
    opacity: 0;
}

.next-step-enter-from,
.previous-step-leave-active {
    transform: translateX(100vw);
    opacity: 0;
}
</style>

<style lang="css">
#campaign-deactivation-reason > p > a {
    text-decoration: underline !important;
    color: var(--color-primary) !important;
}
</style>
