<!-- Create a Vue 3 component with a fixed bar using tailwind and script setup syntax -->
<!-- The component should use have the fixed bar and a slot -->
<template>
    <div
        class="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200"
    >
        <div class="p-4 pl-80">
            <slot></slot>
        </div>
    </div>
</template>
