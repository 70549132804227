<template>
    <div class="flex flex-col items-center">
        <div
            class="hidden lg:flex text-sm lg:text-lg font-semibold text-black text-left lg:text-center pb-3 lg:pb-0"
        >
            {{ t('user.email.send_with') }}
        </div>
        <div class="flex justify-between items-center max-w-fit mb-7 space-x-4">
            <div
                v-for="emailClient in UsedEmailClients"
                :key="emailClient.value"
                class="hidden lg:flex"
                :class="{
                    selected: emailClient.value === selectedEmailClient,
                }"
            >
                <span
                    class="flex flex-col items-center justify-center m-0 lg:m-2 cursor-pointer"
                    @click="selectEmailClient(emailClient.value)"
                >
                    <div
                        class="email-client-box flex items-center justify-center h-12 w-12 border rounded-lg"
                    >
                        <img
                            class="h-6 w-6"
                            :src="emailClient.icon"
                            alt="Gmail Icon"
                        />
                    </div>
                    <div class="text-center text-xs mt-1">
                        {{ emailClient.label }}
                    </div>
                </span>
            </div>
        </div>
        <AVButton
            @click="onSendButtonClicked"
            class="w-full lg:w-fit font-semibold px-10"
            button-type="secondary"
            :sticky-on-mobile="true"
        >
            {{ t('user.email.send_the_email') }}
            <i
                class="fi fi-rs-angle-right inline-block translate-y-0.5 hidden lg:inline"
            ></i
        ></AVButton>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import AVButton from '@/components/forms/AVButton.vue'
import { SendButtonsEmits } from '../../types'
import gmailIcon from '@/assets/img/mail-clients/gmail.svg'
import outlookIcon from '@/assets/img/mail-clients/outlook.svg'
import yahooIcon from '@/assets/img/mail-clients/yahoo.svg'
import icloudIcon from '@/assets/img/mail-clients/icloud.svg'
import mailIcon from '@/assets/img/mail-clients/mail.svg'
import { useI18n } from 'vue-i18n'
import { RecipientPublic } from '@/models/recipients'
import { EmailClient } from '@/types'
import { combineBodyAndSignature } from './utils'

interface Props {
    sendTo: Array<RecipientPublic>
    ccList: Array<RecipientPublic>
    bccList: Array<string | undefined>
    subject: string
    body: string
    signature: string
}

interface Emits extends SendButtonsEmits {}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()
const bodyAndSignature = computed(() =>
    combineBodyAndSignature(props.body, props.signature)
)
const selectedEmailClient = ref<EmailClient>('gmail')

const UsedEmailClients = [
    { label: 'Gmail', value: 'gmail', icon: gmailIcon },
    { label: 'Outlook', value: 'outlook', icon: outlookIcon },
    { label: 'Yahoo', value: 'yahoo', icon: yahooIcon },
    { label: 'iCloud', value: 'icloud', icon: icloudIcon },
    { label: 'Mail', value: 'mail', icon: mailIcon },
] as Array<{ label: string; value: EmailClient; icon: string }>

const isMobile = computed(() => /Mobi|Android/i.test(navigator.userAgent))

function createMailtoLink(
    emailClient: EmailClient,
    send_to: Array<string | undefined>,
    cc_list: Array<string | undefined>,
    bcc_list: Array<string | undefined>
) {
    const send_to_filtered = send_to.filter((email) => !!email)
    if (send_to_filtered.length === 0) {
        throw new Error('No recipients found')
    }
    const to = send_to.join(',')
    const cc = cc_list.filter((email) => !!email).join(',')
    const bcc = bcc_list.filter((email) => !!email).join(',')
    const subject = encodeURIComponent(props.subject)
    const emailBody = encodeURIComponent(bodyAndSignature.value)

    // Use mailto: for mobile devices, regardless of the selected client
    if (isMobile.value || emailClient === 'mail') {
        return `mailto:${to}?cc=${cc}&bcc=${bcc}&subject=${subject}&body=${emailBody}`
    }

    switch (emailClient) {
        case 'gmail':
            return `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${to}&cc=${cc}&bcc=${bcc}&su=${subject}&body=${emailBody}`
        case 'yahoo':
            return `https://compose.mail.yahoo.com/?to=${to}&cc=${cc}&bcc=${bcc}&subj=${subject}&body=${emailBody}`
        case 'outlook':
            return `https://outlook.live.com/mail/0/deeplink/compose?to=${to}&cc=${cc}&bcc=${bcc}&subject=${subject}&body=${emailBody}`
        case 'icloud':
            return `https://www.icloud.com/mail#compose?to=${to}&cc=${cc}&bcc=${bcc}&subject=${subject}&body=${emailBody}`
        case 'aol':
            return `https://mail.aol.com/webmail-std/en-us/suite#mail?compose=true&to=${to}&cc=${cc}&bcc=${bcc}&subject=${subject}&content=${emailBody}`
        case 'zoho':
            return `https://mail.zoho.com/mail/compose?to=${to}&cc=${cc}&bcc=${bcc}&subject=${subject}&content=${emailBody}`
        case 'protonmail':
            return `https://mail.protonmail.com/new?to=${to}&cc=${cc}&bcc=${bcc}&subject=${subject}&body=${emailBody}`
    }
}

const selectEmailClient = (clickedEmailClient: EmailClient) => {
    selectedEmailClient.value = clickedEmailClient
}

const onSendButtonClicked = () => {
    const openedEmailClient = selectedEmailClient.value ?? 'gmail'

    const sendTo = props.sendTo.map((recipient) => recipient.email)
    const ccList = props.ccList.map((recipient) => recipient.email)
    const bccList = props.bccList

    const link = createMailtoLink(openedEmailClient, sendTo, ccList, bccList)

    if (isMobile.value) {
        window.open(link, '_self')
    } else {
        window.open(link, '_blank')
    }

    emit('sendButtonClicked')
}
</script>

<style scoped>
.selected {
    font-weight: 600;
}

.selected .email-client-box {
    border: 2px solid var(--color-secondary);
}
</style>
