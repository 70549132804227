<template>
    <span
        class="absolute z-20 hidden group-hover:block top-0 left-5 mb-2 px-2 py-1 bg-gray-100 text-black text-xs rounded-md shadow-lg whitespace-nowrap"
    >
        {{ props.message }}
    </span>
</template>
<script setup lang="ts">
interface Props {
    message: string
}

const props = defineProps<Props>()
</script>
