import { RList, RListWrite } from '@/models/rlists'
import {
    createRList,
    deleteRList,
    listRList,
    readRList,
    updateRList,
} from './requests'
import { defineCrudStore } from '@/stores/crud'
import { operations } from '@/schema'

export const useRListsBackofficeCrudStore = defineCrudStore<
    RList,
    RListWrite,
    NonNullable<
        operations['activoice_recipients_rlists_list']['parameters']['query']
    > & { page: number }
>(
    'Backoffice:RLists:Crud',
    {
        listRequest: listRList,
        readRequest: readRList,
        createRequest: createRList,
        updateRequest: updateRList,
        deleteRequest: deleteRList,
    },
    () => ({
        page: 1,
    })
)
