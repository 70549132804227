import { operations } from '@/schema'
import { parse } from 'best-effort-json-parser'
import { API_ROOT_URL } from '@/constants'
import { interceptAnyResponse } from '@/services/requests'
import { GeneratedContentStream } from './types'
import pickBy from 'lodash/pickBy'
import axios from 'axios'
import { ModelId } from '@/models/types'
import { toWktPoint } from '@/utils/geometry'
import { AddressSearchResults } from '../types'

// TODO : Timeout for reader.read
export async function* readGenerateContentStream(
    response: Response
): AsyncGenerator<GeneratedContentStream, void, unknown> {
    if (!response.body) {
        throw new Error('ReadableStream not yet supported in this browser.')
    }

    const reader = response.body.getReader()
    const textDecoder = new TextDecoder()
    let receivedData = ''
    while (true) {
        const { done, value } = await reader.read()

        if (done) {
            break
        }
        if (!value) {
            continue
        }
        receivedData += textDecoder.decode(value)

        let parsedContent: GeneratedContentStream
        try {
            parsedContent = parse(receivedData)
        } catch (error) {
            if (
                error instanceof SyntaxError &&
                [
                    'unexpected character',
                    'bad control character',
                    'no parser registered',
                ].some((str) =>
                    error.message.toLowerCase().includes(str.toLowerCase())
                )
            ) {
                throw error
            }
            continue
        }

        // Remove undefined values so its easier for the consumer to
        // patch the current generated content
        yield pickBy(parsedContent, (value) => value !== undefined)
    }
}

/**
 * Warning : this function uses `fetch` as opposed to other functions in
 * the app which use `axios`. Therefore all the default that apply to
 * axios don't apply here.
 */
export const openGenerateContentStream = async (
    params: operations['api_genai_generate_content_create']['requestBody']['content']['application/json']
) => {
    return interceptAnyResponse(
        await fetch(`${API_ROOT_URL}/api/genai/generate-content/`, {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
            },
            signal: AbortSignal.timeout(15000),
        })
    )
}

export const updateSentStatus = async (
    params: operations['api_genai_mark_as_sent_create']['requestBody']['content']['application/json']
) =>
    axios.post(`${API_ROOT_URL}/api/genai/mark-as-sent/`, params, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
        },
    })

export const searchAddressRequest = async (address: string) =>
    axios.get<AddressSearchResults>(
        `https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(address)}`
    )

export const findRecipientWithGeoloc = async (
    latLng: [number, number],
    rlistIds: Array<ModelId>
) =>
    axios.get<
        operations['api_recipients_find_w_geoloc_list']['responses']['200']['content']['application/json']
    >(
        `${API_ROOT_URL}/api/recipients/find_w_geoloc/?user_position=${encodeURIComponent(
            toWktPoint(latLng)
        )}&rlist_ids=${Array.from(new Set(rlistIds)).join(',')}`
    )

export const findGeolocRequest = async (
    latLng: [number, number],
    geoType: string,
    strict: boolean
) =>
    axios.get<
        operations['api_recipients_find_geoloc_list']['responses']['200']['content']['application/json']
    >(
        `${API_ROOT_URL}/api/recipients/find_geoloc/?user_position=${encodeURIComponent(
            toWktPoint(latLng)
        )}&geo_type=${geoType}&strict=${strict}`
    )
