<template>
    <th class="pl-4 text-left text-sm py-3 font-bold" @click="onSortClicked">
        <slot></slot>
        <!-- <i :class="`ml-2 ${iconClass} cursor-pointer`"></i> -->
    </th>
</template>
<script setup lang="ts" generic="T">
// import { computed } from 'vue'
import { ListSort } from '@/AppBackoffice/types'

interface Props {
    column: keyof T
    sort?: ListSort<T>
}

interface Emits {
    (event: 'sortUpdated', sort: ListSort<T>): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const iconClass = computed(() =>
//     props.column === props.sort.sortByColumn
//         ? props.sort.sortOrder === 'asc'
//             ? 'fi fi-rs-angle-small-up'
//             : 'fi fi-rs-angle-small-down'
//         : 'fi fi-rs-sort-amount-up-alt'
// )

const onSortClicked = () => {
    if (!props.sort) {
        return
    }
    emit('sortUpdated', {
        sortByColumn: props.column,
        sortOrder:
            props.column === props.sort.sortByColumn &&
            props.sort.sortOrder === 'asc'
                ? 'desc'
                : 'asc',
    })
}
</script>
