<template>
    <div class="relative">
        <LoaderWithOverlay v-if="myOrganizationStore.isBusy" />
        <ContainerWhite v-else-if="myOrganizationStore.myOrganization">
            <h2 class="text-2xl font-semibold text-gray-900">
                {{ t('backoffice.organizations.title') }}
            </h2>
            <OrganizationForm
                :organization="myOrganizationStore.myOrganization!"
                :validation-errors="myOrganizationStore.validationErrors"
                submit-t-key="backoffice.actions.save"
                @submit="onSubmitClicked"
            />
        </ContainerWhite>
    </div>
</template>

<script setup lang="ts">
import LoaderWithOverlay from '@/components/LoaderWithOverlay.vue'
import OrganizationForm from './OrganizationForm.vue'
import { useMyOrganizationStore } from './stores'
import { onBeforeMount } from 'vue'
import { OrganizationWrite } from '@/models/organizations'
import ContainerWhite from '@/components/containers/ContainerWhite.vue'
import { useI18n } from 'vue-i18n'
import { RequestStatus } from '@/types'
import { useToastsStore } from '@/stores/toasts'

const toastsStore = useToastsStore()
const { t } = useI18n()

const myOrganizationStore = useMyOrganizationStore()

onBeforeMount(myOrganizationStore.runRead)

const onSubmitClicked = async (organization: OrganizationWrite) => {
    delete organization.logo
    const status = await myOrganizationStore.runUpdate(organization)
    if (status == RequestStatus.SUCCESS) {
        toastsStore.addMessageTKey('backoffice.messages.success', 'success')
    } else {
    }
}
</script>
