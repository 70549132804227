<template>
    <div>
        <LoaderWithOverlay v-if="isBusy" />
        <div class="container mx-auto px-4 py-8">
            <RouterView></RouterView>
            <ListHeader
                :titleTKey="titleTKey"
                :descriptionTKey="descriptionTKey"
                :createActionTKey="createActionTKey"
                :createRouteName="createRouteName"
                :downloadTKey="downloadTKey"
                @download-clicked="emit('downloadClicked')"
            ></ListHeader>
            <div v-if="listMeta" class="container py-2 w-full">
                <PaginationAndFilter
                    :list-filters="listFilters"
                    :list-meta="listMeta"
                    :route-name="routeName"
                />
                <div v-if="isListDataEmpty">
                    <ListEmptyLayout routeName="rlist_create">
                        <template #no-element-msg>
                            <p>You have no created any lists yet</p>
                        </template>
                        <template #create-element-msg>
                            Create your first list
                        </template>
                    </ListEmptyLayout>
                </div>
                <div v-else>
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import ListHeader from '@/AppBackoffice/components/ListHeader.vue'
import { ListMeta, ListFiltersDefault } from '@/AppBackoffice/types'

interface Props {
    isBusy: boolean
    titleTKey: TKey
    descriptionTKey: TKey
    createActionTKey: TKey
    createRouteName: string
    downloadTKey?: TKey
    listMeta: ListMeta | null
    listFilters: ListFiltersDefault
    isListDataEmpty: boolean
    routeName: string
    filter?: string
}

defineProps<Props>()

interface Emits {
    (event: 'downloadClicked'): void
}

const emit = defineEmits<Emits>()

import { RouterView } from 'vue-router'
import LoaderWithOverlay from '@/components/LoaderWithOverlay.vue'
import { TKey } from '@/i18n'
import PaginationAndFilter from '@/AppBackoffice/components/PaginationAndFilter.vue'
import ListEmptyLayout from '@/AppBackoffice/components/ListEmptyLayout.vue'
</script>

<style scoped></style>
