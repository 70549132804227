import { Recipient, RecipientSimple, RecipientWrite } from '@/models/recipients'
import axios from 'axios'
import { ListResponseData } from '@/types'
import { ModelId } from '@/models/types'
import { API_ROOT_URL } from '@/constants'
import { operations } from '@/schema'
import { serializeQueryParams } from '@/utils/requests'

export const listRecipients = async (
    params: NonNullable<
        operations['activoice_recipients_list']['parameters']['query']
    > & { page: number }
) => {
    return axios.get<ListResponseData<Recipient>>(
        `${API_ROOT_URL}/activoice/recipients/`,
        { params: serializeQueryParams(params) }
    )
}

export const listAllRecipientsSimple = async () => {
    return await axios.get<Array<RecipientSimple>>(
        `${API_ROOT_URL}/activoice/recipients/recipients_simple/`
    )
}

export const createRecipient = async (recipient: RecipientWrite) => {
    return axios.post<Recipient>(
        `${API_ROOT_URL}/activoice/recipients/`,
        recipient
    )
}

export const readRecipient = (recipientId: ModelId) => {
    return axios.get<Recipient>(
        `${API_ROOT_URL}/activoice/recipients/${recipientId}/`
    )
}

export const updateRecipient = (
    recipientId: ModelId,
    recipient: RecipientWrite
) => {
    return axios.put<Recipient>(
        `${API_ROOT_URL}/activoice/recipients/${recipientId}/`,
        recipient
    )
}

export const deleteRecipient = (recipientId: ModelId) => {
    return axios.delete(`${API_ROOT_URL}/activoice/recipients/${recipientId}/`)
}

export const downloadCSVRecipients = async (rlistId: ModelId | null = null) => {
    return await axios.get(
        `${API_ROOT_URL}/activoice/recipients/recipients_csv/`,
        {
            responseType: 'blob',
            params: serializeQueryParams({
                rlist: rlistId,
            }),
        }
    )
}
