<template>
    <StepContainer :campaign-id="props.campaignId">
        <StepTitle
            :titles="[
                {
                    title: store.interpellation!.description || '',
                },
            ]"
        />
        <StepContainerInner>
            <StepInstructions>
                {{ t('user.campaign.select_interpellation_mode') }}
            </StepInstructions>
            <InterpellationModesButtonList
                :custom-form-button-text="
                    store.interpellation!.custom_button_text
                        ? store.interpellation!.custom_button_text
                        : t('user.campaign.interpellation_mode.form')
                "
                :interpellationModesAvailable="
                    store.interpellationModesAvailableForSelectedRecipients
                "
                @choose-interpellation-mode="onChooseInterpellationMode"
            />
        </StepContainerInner>
    </StepContainer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
    useSendCampaignStore,
    useSendCampaignWizardStore,
} from '@/AppCampaigns/stores'
import { InterpellationMode } from '@/models/campaigns'
import { onBeforeMount } from 'vue'
import InterpellationModesButtonList from './InterpellationModesButtonList.vue'
import StepTitle from '../StepTitle.vue'
import StepContainer from '@/components/containers/StepContainer.vue'
import StepContainerInner from '@/components/containers/StepContainerInner.vue'
import StepInstructions from '../StepInstructions.vue'

interface Props {
    campaignId: string | null
}
const props = defineProps<Props>()

const store = useSendCampaignStore(props.campaignId)
const wizardStore = useSendCampaignWizardStore(props.campaignId)
const { t } = useI18n()

const onChooseInterpellationMode = (interpellationMode: InterpellationMode) => {
    store.setInterpellationMode(interpellationMode)
    wizardStore.goToNextStep()
}

onBeforeMount(() => {
    if (!store.campaign) {
        throw new Error('Store should have a campaign set')
    }
    if (!store.interpellation) {
        throw new Error('Store should have a interpellation set')
    }
    store.setInterpellationMode(null)
})
</script>
