<template>
    <ContainerWhite>
        <div class="font-semibold mb-2">My Emails</div>
        <div v-for="email in emailList" :key="email.email" class="mt-3">
            <div
                class="md:grid md:grid-cols-4 justify-between items-center flex-col"
            >
                <div>{{ email.email }}</div>
                <div>
                    <AVButton
                        v-if="!email.verified"
                        @click="verifyEmail(email.email)"
                        class="text-red-500"
                        >Verify</AVButton
                    >
                    <span v-else>Verified</span>
                </div>
                <div>
                    <AVButton
                        v-if="!email.primary"
                        @click="setPrimary(email.email)"
                        class="text-red-500"
                        >Set Primary</AVButton
                    >
                    <span v-else>Primary</span>
                </div>
                <div>
                    <AVButton
                        @click="emailToDelete = email.email"
                        buttonType="outline-danger"
                        >Delete</AVButton
                    >
                </div>
            </div>
        </div>
        <form @submit.prevent="addEmail" class="mt-4">
            <div class="font-semibold mb-2">Add Email</div>
            <AVInput
                :name="'email'"
                class="custom-input mt-3"
                label="E-mail"
                type="email"
                v-model="email"
                placeholder="New email"
            />
            <AVButton type="submit" class="mt-4">Add</AVButton>
        </form>
        <AVModal
            v-if="emailToDelete"
            v-model="emailToDelete"
            @closeModal="emailToDelete = ''"
        >
            <template v-slot:content>
                Are you sure you want to delete the email {{ emailToDelete }}?
                <div class="justify-end">
                    <div class="flex space-x-2 justify-end">
                        <AVButton
                            button-type="outline-primary"
                            @click="emailToDelete = ''"
                        >
                            Cancel
                        </AVButton>
                        <AVButton
                            @click="deleteEmail()"
                            button-type="outline-danger"
                        >
                            Delete
                        </AVButton>
                    </div>
                </div>
            </template>
        </AVModal>
    </ContainerWhite>
</template>

<script setup lang="ts">
import ContainerWhite from '@/components/containers/ContainerWhite.vue'
import AVButton from '@/components/forms/AVButton.vue'
import AVInput from '@/components/forms/AVInput.vue'
import { useAuthStore } from './stores'
import { onMounted, ref } from 'vue'
import { useToastsStore } from '@/stores/toasts'
import { Email } from '@/models/user'
import AVModal from '@/components/AVModal.vue'
import { RequestStatus } from '@/types'

const toastsStore = useToastsStore()

const email = ref('')
const emailList = ref<Array<Email>>([])

const emailToDelete = ref('')

const store = useAuthStore()

onMounted(async () => {
    await store.listEmails()
    emailList.value = store.listEmailsRequestTracker.getLoadedData()!.data
})

const addEmail = async () => {
    const responseStatus = await store.addEmail(email.value)
    if (responseStatus !== RequestStatus.SUCCESS) {
        for (const error of (
            store.listEmailsRequestTracker.state.error!.data as {
                errors: Array<{ message: string }>
            }
        ).errors) {
            toastsStore.addMessage(error.message, 'danger')
        }
    } else {
        toastsStore.addMessageTKey('backoffice.user.emailAdded', 'success')
        emailList.value = store.listEmailsRequestTracker.getLoadedData()!.data
        email.value = ''
    }
}

const deleteEmail = async () => {
    const responseStatus = await store.removeEmail(emailToDelete.value)
    if (responseStatus !== RequestStatus.SUCCESS) {
        for (const error of (
            store.listEmailsRequestTracker.state.error!.data as {
                errors: Array<{ message: string }>
            }
        ).errors) {
            toastsStore.addMessage(error.message, 'danger')
        }
    } else {
        toastsStore.addMessageTKey('backoffice.user.emailDeleted', 'success')
        emailList.value = store.listEmailsRequestTracker.getLoadedData()!.data
    }
    emailToDelete.value = ''
}

const setPrimary = async (email: string) => {
    const responseStatus = await store.setPrimary(email)
    if (responseStatus !== RequestStatus.SUCCESS) {
        for (const error of (
            store.statusRequestTracker.state.error!.data as {
                errors: Array<{ message: string }>
            }
        ).errors) {
            toastsStore.addMessage(error.message, 'danger')
        }
    } else {
        toastsStore.addMessageTKey('backoffice.user.emailSetPrimary', 'success')
        await store.listEmails()
        emailList.value = store.listEmailsRequestTracker.getLoadedData()!.data
    }
}

const verifyEmail = async (email: string) => {
    const requestStatus = await store.startEmailVerification(email)
    if (requestStatus !== RequestStatus.SUCCESS) {
        toastsStore.addMessageTKey(
            'backoffice.user.emailVerificationFailed',
            'danger'
        )
    } else {
        toastsStore.addMessageTKey(
            'backoffice.user.emailVerificationSent',
            'success'
        )
    }
}
</script>
