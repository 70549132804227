<template>
    <div class="w-full flex flex-col lg:flex-row lg:space-y-0 lg:space-x-4">
        <AVSelect
            class="flex-1 lg:flex-auto max-lg:mb-2"
            name="selectedInterpellationIndex"
            v-model="selectedInterpellationIndex"
            :placeholder="t(placeholderTKey)"
            :options="interpellationOptions"
        />
        <AVButton
            :disabled="selectedInterpellationIndex == null"
            class="w-full lg:w-fit"
            :sticky-on-mobile="true"
            @click="onSubmitSelectedInterpellation"
            >{{ t(buttonTKey)
            }}<i
                class="fi fi-rs-arrow-alt-right ml-3 lg:hidden relative text-lg top-[4px]"
        /></AVButton>
    </div>
</template>
<script setup lang="ts">
import AVSelect from '@/components/forms/AVSelect.vue'
import AVButton from '@/components/forms/AVButton.vue'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { TKey } from '@/i18n'

interface Props {
    placeholderTKey: TKey
    buttonTKey: TKey
    interpellationOptions: Array<{ label: string; value: number }>
}

interface Emits {
    (event: 'submit-interpellation', interpellationIndex: number | null): void
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()
const selectedInterpellationIndex = ref<number | null>(null)

const onSubmitSelectedInterpellation = () => {
    emit('submit-interpellation', selectedInterpellationIndex.value)
}
</script>
