<template>
    <div class="flex flex-col relative w-full h-full">
        <LoaderWithOverlay v-if="loadCampaignStore.isLoading" />
        <template v-else>
            <CampaignHeader
                :title="loadCampaignStore.title || ''"
                :campaign-id="campaignId"
            />
            <SendCampaign
                v-if="loadCampaignStore.campaign"
                class="flex-1"
                :campaign="loadCampaignStore.campaign"
            />
            <LoadingErrorMessage
                v-else-if="loadCampaignStore.loadingError"
                :error="loadCampaignStore.loadingError"
            />
        </template>
    </div>
</template>

<script setup lang="ts">
import SendCampaign from '@/AppCampaigns/SendCampaign/SendCampaign.vue'
import CampaignHeader from '@/AppCampaigns/components/CampaignHeader.vue'
import LoadingErrorMessage from './components/LoadingErrorMessage.vue'
import { onMounted } from 'vue'
import { useLoadCampaignStore } from './stores'
import LoaderWithOverlay from '@/components/LoaderWithOverlay.vue'

interface Props {
    campaignId: string
}

const props = defineProps<Props>()

const loadCampaignStore = useLoadCampaignStore(props.campaignId)

onMounted(async () => {
    await loadCampaignStore.loadCampaign(props.campaignId)
    loadCampaignStore.updateColors()
})
</script>
