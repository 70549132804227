<template>
    <div class="space-y-8">
        <ContainerWhite class="border-l-8 mb-5">
            <div class="text-xl font-black mb-2">
                {{
                    t(
                        'backoffice.campaigns.createUpdateCampaign.stepCustomQuestionsTitle'
                    )
                }}
            </div>
            <div class="text-sm text-gray-500 text-thin">
                {{
                    t(
                        'backoffice.campaigns.createUpdateCampaign.stepCustomQuestionsDescription'
                    )
                }}
            </div>
        </ContainerWhite>

        <ContainerWhite>
            <div class="text-xl font-black mb-2">
                {{ t('backoffice.customQuestions.title') }}
            </div>

            <form id="custom-questions-form" @submit.prevent="onSubmit">
                <CustomQuestionFields
                    v-for="(_, index) in customQuestionsField.value.value"
                    class="mb-5"
                    :key="index"
                    :index="index"
                    :question-path="`custom_questions.${index}`"
                    :language="currentCampaign!.language || (locale as Locale)"
                    @remove-custom-question="removeCustomQuestion(index)"
                />
            </form>

            <ContainerBoxShadow v-if="isAddingNewQuestion">
                <form
                    class="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 mt-5 mb-3"
                    @submit.prevent="getQuestionData"
                >
                    <div class="flex-1 lg:flex-auto lg:w-2/3">
                        <AVInput
                            name="newQuestionText"
                            v-model="newQuestionText"
                            :placeholder="'Ex: What is your address?'"
                        />
                    </div>
                    <AVButton v-if="!isReformulatingQuestion" type="submit">
                        Add question
                    </AVButton>
                    <AVLoader v-if="isReformulatingQuestion" />
                </form>
            </ContainerBoxShadow>
            <div
                @click="addCustomQuestion"
                class="text-primary text-sm cursor-pointer"
                v-if="!isAddingNewQuestion"
            >
                <i class="fi fi-rs-add"></i>
                <span class="underline inline-block -translate-y-0.5 ml-1"
                    >Add custom question</span
                >
            </div>
        </ContainerWhite>

        <AVButton
            button-type="link"
            v-if="!isShowingAdvancedSettings"
            @click="onShowAdvancedSettings"
        >
            Show advanced customization settings
        </AVButton>
        <ContainerWhite v-if="isShowingAdvancedSettings">
            <div class="text-xl font-black mb-2">
                {{ t('backoffice.customQuestions.advancedSettingsTitle') }}
            </div>
            <form v-if="isShowingAdvancedSettings">
                <AVInput
                    v-bind="customPromptField.props.value"
                    v-on="customPromptField.listeners.value"
                    :label="t('backoffice.campaigns.customPrompt')"
                    :placeholder="
                        t('backoffice.campaigns.customPromptPlaceholder')
                    "
                />
            </form>
            <AVButton
                button-type="link"
                @click="showPromptPreview = true"
                class="mt-5"
                >Preview prompt</AVButton
            >
            <AVModalWithRouter
                v-if="showPromptPreview"
                :inModal="false"
                titleTKey="backoffice.campaigns.promptPreview"
                @close-modal="showPromptPreview = false"
            >
                <template #content>
                    <PreviewPrompt
                        :custom-prompt="customPromptField.value.value || ''"
                    />
                </template>
            </AVModalWithRouter>
        </ContainerWhite>
        <FixedMenu>
            <SaveButtons
                form-id="custom-questions-form"
                @save="onSave"
                @previous="onClickPreviousStep"
                :has-next="true"
                :has-previous="true"
            />
        </FixedMenu>
    </div>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { HttpStatusCode } from 'axios'
import { RequestStatus } from '@/types'
import {
    useCampaignBackofficeCrudStore,
    useCampaignCreateUpdateWizardStore,
} from '@/AppBackoffice/campaigns/stores'
import AVModalWithRouter from '@/components/AVModalWithRouter.vue'
import { makeUseField } from '@/utils/forms'
import FixedMenu from '../components/FixedMenu.vue'
import SaveButtons from '../components/SaveButtons.vue'
import AVButton from '@/components/forms/AVButton.vue'
import AVInput from '@/components/forms/AVInput.vue'
import AVLoader from '@/components/AVLoader.vue'
import ContainerWhite from '@/components/containers/ContainerWhite.vue'
import ContainerBoxShadow from '@/components/containers/ContainerBoxShadow.vue'
import { storeToRefs } from 'pinia'
import { Campaign, CampaignWrite } from '@/models/campaigns'
import { useI18n } from 'vue-i18n'
import { Locale } from '@/i18n'
import CustomQuestionFields from './CustomQuestionFields.vue'
import PreviewPrompt from './PreviewPrompt.vue'
import { useRequestTracker } from '@/utils/request-tracker'
import { reformulateQuestion } from '@/AppBackoffice/campaigns/requests'
import { useForm } from 'vee-validate'
import { campaignStepCustomQuestionsValidator } from './validation'
import { CustomQuestion } from '@/models/custom-questions'
import { usePredefinedToasts } from '../utils'
import { useToastsStore } from '@/stores/toasts'

const predefinedToasts = usePredefinedToasts()

interface Props {
    campaignId: string
}

const props = defineProps<Props>()

const showPromptPreview = ref<boolean>(false)
const newQuestionText = ref<string>('')
const isAddingNewQuestion = ref<boolean>(false)
const isShowingAdvancedSettings = ref(false)
const reformulateQuestionRequestTracker = useRequestTracker<CustomQuestion>()
const isReformulatingQuestion = computed<boolean>(
    reformulateQuestionRequestTracker.isRequestInProgress
)
const crudStore = useCampaignBackofficeCrudStore()
const wizardStore = useCampaignCreateUpdateWizardStore()
const { itemData: currentCampaign } = storeToRefs(crudStore)
const { locale, t } = useI18n()
const {
    validate,
    values: campaign,
    meta,
} = useForm({
    validationSchema: campaignStepCustomQuestionsValidator,
    initialValues: currentCampaign.value,
})

const useFieldCampaign = makeUseField<CampaignWrite>()
const customQuestionsField = useFieldCampaign('custom_questions')
const customPromptField = useFieldCampaign('custom_prompt')

const addCustomQuestion = () => {
    if (isAddingNewQuestion.value === true) {
        return
    }
    newQuestionText.value = ''
    isAddingNewQuestion.value = true
}

const removeCustomQuestion = (index: number) => {
    customQuestionsField.value.value!.splice(index, 1)
}

const onClickPreviousStep = async () => {
    const wasSaved = await onSave()
    if (wasSaved) {
        wizardStore.goToPreviousStep()
    }
}

const toastStore = useToastsStore()

const onSave = async () => {
    const { valid: isValid } = await validate()
    if (!isValid) {
        toastStore.addMessageTKey(
            'backoffice.messages.validationFailed',
            'danger'
        )
        return false
    }
    const updatedCampaign: Campaign = {
        ...campaign,
        // Not sure why but custom_questions ends up being undefined
        // if the array is empty
        custom_questions: customQuestionsField.value.value || [],
    }
    const requestStatus = await crudStore.runUpdate(
        props.campaignId,
        updatedCampaign
    )
    if (requestStatus === RequestStatus.SUCCESS) {
        wizardStore.setDirtyState(false)
        predefinedToasts.success()
    }
    return requestStatus
}

const onSubmit = async () => {
    if ((await onSave()) === RequestStatus.SUCCESS) {
        wizardStore.goToNextStep()
    }
}

const onShowAdvancedSettings = () => {
    isShowingAdvancedSettings.value = true
}

const getQuestionData = async () => {
    const requestStatus = await reformulateQuestionRequestTracker.runRequest(
        reformulateQuestion,
        HttpStatusCode.Ok,
        [
            {
                question: newQuestionText.value,
                language:
                    currentCampaign.value!.language || (locale.value as Locale),
            },
        ]
    )

    if (requestStatus === RequestStatus.SUCCESS) {
        customQuestionsField.value.value =
            customQuestionsField.value.value || []
        customQuestionsField.value.value.push(
            reformulateQuestionRequestTracker.getLoadedData()!
        )
    }

    isAddingNewQuestion.value = false
}
watch(() => meta.value.dirty, wizardStore.setDirtyState)
</script>
