<template>
    <div ref="container">
        <Transition
            name="crossfade"
            v-for="({ title, subtitle }, index) in titles"
            :key="index"
        >
            <div
                class="text-left md:text-center relative w-full mb-6"
                :class="{
                    invisible: isInitialized === false,
                }"
                v-if="isInitialized === false || props.titleIndex === index"
            >
                <span
                    class="text-base lg:text-2xl font-bold text-black mb-2"
                    id="title-description"
                    :class="{
                        'text-red-700': props.errorHappened,
                    }"
                    v-html="title"
                >
                </span>
                <ThreeDotsFlashing v-if="props.isLoading" />
                <div v-if="subtitle">{{ subtitle }}</div>
            </div>
        </Transition>
    </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue'
import ThreeDotsFlashing from '../components/ThreeDotsFlashing.vue'

interface Props {
    titles: Array<{ title: string; subtitle?: string }>
    titleIndex?: number
    isLoading?: boolean
    errorHappened?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    titleIndex: 0,
    isLoading: false,
    errorHappened: false,
})

const isInitialized = ref(false)
const container = ref<HTMLDivElement>()

/**
 * On mounted, compute the height of the highest title that will be displayed
 * that way when switching we avoid the container from resizing, and causing
 * the page to jump.
 */
const loadTitle = () => {
    isInitialized.value = true
}
onMounted(loadTitle)
</script>
<style scoped>
.crossfade-enter-active,
.crossfade-leave-active {
    transition:
        transform 0.2s linear,
        opacity 0.2s linear;
}

.crossfade-enter-from,
.crossfade-leave-to {
    opacity: 0;
}

.crossfade-enter-from {
    transform: translateY(-200%);
}

.crossfade-leave-to {
    transform: translateY(200%);
}
</style>
<style lang="css">
#title-description > p > a {
    text-decoration: underline !important;
    color: var(--color-primary) !important;
}
</style>
