<template>
    <div>
        <ListPage
            :is-busy="crudStore.isBusy"
            title-t-key="backoffice.campaigns.campaigns"
            description-t-key="backoffice.campaigns.description"
            create-action-t-key="backoffice.actions.create"
            create-route-name="campaign_create"
            :is-list-data-empty="crudStore.isListDataEmpty"
            route-name="campaigns_list"
            :list-meta="crudStore.listMeta"
            :list-filters="crudStore.listFilters"
        >
            <table
                class="min-w-full table-auto divide-y divide-gray-200 border-separate border-spacing-y-3 border-spacing-x-0"
            >
                <thead class="bg-white">
                    <tr class="rounded-t-xl">
                        <th
                            scope="col"
                            class="px-6 text-left text-sm py-3 font-bold"
                        >
                            {{ t('backoffice.campaigns.name') }}
                        </th>
                        <th
                            scope="col"
                            class="px-6 text-left text-sm py-3 font-bold"
                        >
                            {{ t('backoffice.campaigns.emailLink') }}
                        </th>
                        <th
                            scope="col"
                            class="px-6 text-left text-sm py-3 font-bold"
                        >
                            {{ t('backoffice.campaigns.updated') }}
                        </th>
                        <th
                            scope="col"
                            class="px-6 text-left text-sm py-3 font-bold"
                        >
                            {{ t('backoffice.campaigns.state.name') }}
                        </th>
                        <th
                            scope="col"
                            class="px-6 text-left text-sm py-3 font-bold"
                        ></th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                    <tr
                        v-for="campaign in crudStore.listData"
                        :key="campaign.id"
                    >
                        <td
                            class="px-6 py-8 rounded-l-lg max-w-[320px] truncate ..."
                        >
                            <div
                                class="text-md text-gray-900 font-semibold"
                                :title="campaign.name"
                            >
                                {{ campaign.name }}
                            </div>
                        </td>
                        <td class="px-6 py-8 whitespace-nowrap">
                            <CampaignLink
                                :campaign-id="campaign.id"
                                :campaign-slug="campaign.slug"
                            >
                                Open Campaign
                            </CampaignLink>
                        </td>
                        <td class="px-6 py-8 whitespace-nowrap">
                            <div class="text-sm text-gray-900">
                                {{
                                    moment(campaign.updated_date).format(
                                        'DD-MM-YYYY'
                                    )
                                }}
                            </div>
                        </td>

                        <td class="px-6 py-8 whitespace-nowrap">
                            <div
                                class="py-1 px-2 rounded-md text-sm f bg-gray-200 text-gray-900 text-center"
                                :class="{
                                    'bg-green-200 text-green-900':
                                        campaign.state === 'active',
                                    'bg-red-200 text-red-900':
                                        campaign.state !== 'active',
                                }"
                            >
                                {{
                                    campaign.state === 'active'
                                        ? t(`backoffice.campaigns.state.active`)
                                        : t(`backoffice.campaigns.state.paused`)
                                }}
                            </div>
                        </td>
                        <td class="px-6 py-8">
                            <ContextMenuEllipsisButton
                                :menu-id="makeMenuId(campaign.id)"
                            />
                        </td>
                        <ContextMenu
                            :menu-id="makeMenuId(campaign.id)"
                            :active-slugs="['view', 'edit', 'delete']"
                            @view-clicked="
                                router.push({
                                    name: 'campaign_view',
                                    params: { campaignId: campaign.id },
                                })
                            "
                            @delete-clicked="
                                onDeleteCampaignClicked(campaign.id)
                            "
                            @update-clicked="
                                onUpdateCampaignClicked(campaign.id)
                            "
                        />
                    </tr>
                </tbody>
            </table>
        </ListPage>
    </div>
</template>
<script setup lang="ts">
import moment from 'moment'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import ContextMenu from '@/components/context-menus/ContextMenu.vue'
import ContextMenuEllipsisButton from '@/components/context-menus/ContextMenuEllipsisButton.vue'
import { useCampaignBackofficeCrudStore } from './stores'
import { ModelId } from '@/models/types'
import { onBeforeMountOrPropChange } from '@/utils/components'
import CampaignLink from '@/AppBackoffice/components/CampaignLink.vue'
import ListPage from '@/AppBackoffice/components/ListPage.vue'

interface Props {
    page: number
    filter?: string
}

const props = defineProps<Props>()
const { t } = useI18n()
const router = useRouter()
const crudStore = useCampaignBackofficeCrudStore()

const onUpdateCampaignClicked = (campaignId: ModelId) => {
    router.push({
        name: 'campaign_edit',
        params: { campaignId },
    })
}

const onDeleteCampaignClicked = (campaignId: ModelId) => {
    router.push({
        name: 'campaign_delete_inPlace',
        params: { campaignId },
    })
}

const makeMenuId = (campaignId: string) => `campaign-${campaignId}`

const onListConfigUpdated = () => {
    crudStore.runList({
        page: props.page,
        filter: props.filter,
    })
}

onBeforeMountOrPropChange(
    [() => props.page, () => props.filter],
    onListConfigUpdated
)
</script>
