<template>
    <StepContainer :campaign-id="props.campaignId">
        <StepContainerInner>
            <StepInstructions>
                {{ t('user.campaign.select_interpellation') }}
            </StepInstructions>
            <InterpellationSelect
                placeholder-t-key="user.forms.select_an_option"
                button-t-key="user.common.next_step"
                @submit-interpellation="onSubmitSelectedInterpellation"
                :interpellationOptions="interpellationOptions"
            />
        </StepContainerInner>
    </StepContainer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
    useSendCampaignStore,
    useSendCampaignWizardStore,
} from '@/AppCampaigns/stores'
import { onBeforeMount, computed } from 'vue'
import InterpellationSelect from './InterpellationSelect.vue'
import StepContainer from '@/components/containers/StepContainer.vue'
import StepContainerInner from '@/components/containers/StepContainerInner.vue'
import StepInstructions from '../StepInstructions.vue'

interface Props {
    campaignId: string | null
}
const props = defineProps<Props>()

const store = useSendCampaignStore(props.campaignId)
const wizardStore = useSendCampaignWizardStore(props.campaignId)
const { t } = useI18n()

const onSubmitSelectedInterpellation = (
    selectedInterpellationIndex: number | null
) => {
    if (selectedInterpellationIndex !== null) {
        store.setInterpellation(selectedInterpellationIndex)
        wizardStore.goToNextStep()
    }
}

const interpellationOptions = computed(() =>
    store.campaign!.interpellations!.map((interpellation, index) => ({
        label: interpellation.name,
        value: index,
    }))
)

onBeforeMount(() => {
    if (!store.campaign) {
        throw new Error('Store should have a campaign set')
    }
    if (
        !store.campaign.interpellations ||
        !store.campaign.interpellations.length
    ) {
        throw new Error('No interpellations to select from')
    }
})
</script>
