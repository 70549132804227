import {
    CampaignPublic,
    CampaignWrite,
    campaignValidationBase,
} from '@/models/campaigns'
import { ContentSample, contentSampleValidator } from '@/models/content-samples'
import {
    InterpellationWrite,
    interpellationValidationBase,
} from '@/models/interpellations'
import { recipientSimpleValidator } from '@/models/recipients'
import { rlistSimpleValidator } from '@/models/rlists'
import { selectContentSampleTypeForInterpellationMode } from '@/models/selectors'
import * as Yup from 'yup'

export const interpellationValidator: Yup.ObjectSchema<InterpellationWrite> =
    Yup.object().shape({
        ...interpellationValidationBase,
        content_samples: Yup.array()
            .of(contentSampleValidator)
            .required()
            .default([]),
        send_to: Yup.array()
            .of(recipientSimpleValidator)
            .required()
            .default([]),
        cc_list: Yup.array()
            .of(recipientSimpleValidator)
            .required()
            .default([]),
        bcc_list: Yup.array()
            .of(recipientSimpleValidator)
            .required()
            .default([]),
        recipient_lists: Yup.array()
            .of(rlistSimpleValidator)
            .required()
            .default([]),
    })

export const campaignStepInterpellationsValidator: Yup.ObjectSchema<CampaignWrite> =
    Yup.object().shape({
        ...campaignValidationBase,
        interpellations: Yup.array()
            .of(interpellationValidator)
            .required()
            .default([]),
        // Not validating at this step
        custom_questions: Yup.array().required().default([]),
        interpellation_modes: Yup.array().required().default([]),
        social_networks_image: Yup.string().nullable().default(null),
        is_tracking_sent_emails: Yup.boolean().default(false),
    })

export const validateInterpellationContentSamplesInterpellationModes = (
    contentSamples: Array<ContentSample> | undefined,
    interpellationModes: CampaignPublic['interpellation_modes']
) => {
    const interpellationModesAvailable = interpellationModes.filter(
        (interpellationMode) =>
            (contentSamples || []).some(
                (contentSample) =>
                    contentSample.type ===
                    selectContentSampleTypeForInterpellationMode(
                        interpellationMode
                    )
            )
    )
    if (interpellationModesAvailable.length === 0) {
        return false
    }
    return true
}
