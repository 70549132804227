import axios, { AxiosResponse } from 'axios'
import { ListResponseData, ExtractQueryParams } from '@/types'
import { ModelId } from '@/models/types'
import {
    Campaign,
    CampaignStats,
    CampaignWrite,
    CampaignStatsListItem,
    CampaignInterpellationStatsListItem,
    AtLeastOne,
} from '@/models/campaigns'
import { CustomQuestion } from '@/models/custom-questions'
import { operations, components } from '@/schema'
import { API_ROOT_URL } from '@/constants'
import { assertTypesMatch, SameKeysGuard } from '@/utils/types'
import { serializeQueryParams } from '@/utils/requests'

export interface CampaignStatsListParams {
    created_end?: Date
    created_start?: Date
    page: number
}

assertTypesMatch<
    SameKeysGuard<
        ExtractQueryParams<
            operations['activoice_campaigns_stats_list']['parameters']['query']
        >,
        CampaignStatsListParams
    >
>()

export const createCampaign = (campaign: CampaignWrite) =>
    axios.post(`${API_ROOT_URL}/activoice/campaigns/`, campaign)

export const listCampaigns = (
    params: NonNullable<
        operations['activoice_campaigns_list']['parameters']['query']
    > & { page: number }
) =>
    axios.get<ListResponseData<Campaign>>(
        `${API_ROOT_URL}/activoice/campaigns/`,
        { params: serializeQueryParams(params) }
    )

export const readCampaign = (campaignId: ModelId) => {
    return axios.get<Campaign>(
        `${API_ROOT_URL}/activoice/campaigns/${campaignId}/`
    )
}

export const getCampaignPrompt = (
    campaignId: ModelId,
    interpellationMode: string,
    params: components['schemas']['PromptPreview']
) => {
    return axios.post(
        `${API_ROOT_URL}/activoice/genai/preview-prompt/${campaignId}/${interpellationMode}/`,
        params
    )
}

export const readCampaignStats = (campaignId: ModelId) => {
    return axios.get<Array<CampaignStats>>(
        `${API_ROOT_URL}/activoice/campaigns/stats/${campaignId}/`
    )
}

export const listCampaignsStats = (params: CampaignStatsListParams) =>
    axios.get<ListResponseData<CampaignStatsListItem>>(
        `${API_ROOT_URL}/activoice/campaigns/stats/`,
        { params: serializeQueryParams(params) }
    )

export const listCampaignStatsByInterpellation = (
    campaignId: ModelId,
    params: CampaignStatsListParams
) =>
    axios.get<Array<CampaignInterpellationStatsListItem>>(
        `${API_ROOT_URL}/activoice/campaigns/stats/interpellations/${campaignId}/`,
        { params: serializeQueryParams(params) }
    )

export const updateCampaign = (
    campaignId: ModelId,
    campaign: CampaignWrite
): Promise<AxiosResponse<Campaign>> =>
    axios.put<Campaign>(
        `${API_ROOT_URL}/activoice/campaigns/${campaignId}/`,
        campaign
    )

export const patchCampaign = (
    campaignId: ModelId,
    campaign: AtLeastOne<CampaignWrite>
): Promise<AxiosResponse<Campaign>> =>
    axios.patch<Campaign>(
        `${API_ROOT_URL}/activoice/campaigns/${campaignId}/`,
        campaign
    )

export const deleteCampaign = (campaignId: ModelId) =>
    axios.delete(`${API_ROOT_URL}/activoice/campaigns/${campaignId}/`)

export const duplicateCampaignRequest = (campaignId: ModelId) =>
    axios.post(`${API_ROOT_URL}/activoice/campaigns/duplicate/${campaignId}/`)

export const reformulateQuestion = (
    question: operations['activoice_genai_generate_custom_question_create']['requestBody']['content']['application/json']
) =>
    axios.post<CustomQuestion>(
        `${API_ROOT_URL}/activoice/genai/generate-custom-question/`,
        question
    )

export const updateCampaignImageRequest = (
    formdata: FormData,
    campaignId: ModelId
) =>
    axios.post<Campaign>(
        `${API_ROOT_URL}/activoice/campaigns/update-image/${campaignId}/`,
        formdata,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    )
