<template>
    <ContainerWhite>
        <form @submit.prevent="onClickUpdatePassword">
            <div class="font-semibold mb-2">Update Your Password</div>
            <AVInput
                type="password"
                label="Current Password"
                v-bind="currentPassword.props.value"
                v-on="currentPassword.listeners.value"
                class="mb-4"
            />
            <AVInput
                type="password"
                label="New Password"
                v-bind="newPassword.props.value"
                v-on="newPassword.listeners.value"
                class="mb-4"
            />
            <AVInput
                type="password"
                label="Confirm Password"
                v-bind="confirmPassword.props.value"
                v-on="confirmPassword.listeners.value"
                class="mb-4"
            />

            <AVButton type="submit" class="mt-4">Update Password</AVButton>
        </form>
    </ContainerWhite>
</template>

<script setup lang="ts">
import ContainerWhite from '@/components/containers/ContainerWhite.vue'
import AVButton from '@/components/forms/AVButton.vue'
import AVInput from '@/components/forms/AVInput.vue'
import { useAuthStore } from './stores'
import { useForm } from 'vee-validate'
import { computed } from 'vue'
import { toTypedSchema } from '@vee-validate/yup'
import { changePasswordValidator } from './validation'
import { makeUseField } from '@/utils/forms'
import { ChangePassword } from '@/models/user'
import { useToastsStore } from '@/stores/toasts'
import { RequestStatus } from '@/types'

const toastsStore = useToastsStore()

const validationSchema = computed(() => toTypedSchema(changePasswordValidator))

const { validate, setErrors, resetForm } = useForm({
    validationSchema,
})

const useChangePasswordField = makeUseField<ChangePassword>()
const currentPassword = useChangePasswordField('old_password')
const newPassword = useChangePasswordField('password')
const confirmPassword = useChangePasswordField('password2')

const store = useAuthStore()

const onClickUpdatePassword = async () => {
    const { valid: isValid, values } = await validate()
    if (isValid) {
        const responseStatus = await store.updatePassword(values!)
        if (responseStatus == RequestStatus.SUCCESS) {
            toastsStore.addMessageTKey(
                'backoffice.user.passwordupdated',
                'success'
            )
            resetForm()
        } else {
            setErrors(store.statusRequestTracker.state.error!.data)
        }
    }
}
</script>
