import * as Sentry from '@sentry/browser'

import axios, {
    HttpStatusCode,
    InternalAxiosRequestConfig,
    AxiosError,
} from 'axios'
import { useAuthStore } from '@/AppBackoffice/user/stores'
import { useToastsStore } from '@/stores/toasts'
import { API_ROOT_URL, PUBLIC_API_ROOT_URL } from '@/constants'
import { Router } from 'vue-router'

export const setupAxiosDefaults = () => {
    // Never throw an error when receiving unexpected HTTP status code
    // We handle all status ourselves in the code.
    axios.defaults.validateStatus = () => true
}

/**
 * These interceptors are used for the app only, not for the embed.
 */
export const setupRequestInterceptorsForApp = (router: Router) => {
    axios.interceptors.request.use(makeInterceptAppRequest(router))
    axios.interceptors.response.use(interceptAnyResponse, interceptAnyError)
}

const makeInterceptAppRequest =
    (router: Router) => async (config: InternalAxiosRequestConfig) => {
        const authStore = useAuthStore()
        config.headers = config.headers || {}

        if (
            config.url?.startsWith(API_ROOT_URL) &&
            !config.url?.startsWith(PUBLIC_API_ROOT_URL) &&
            !authStore.isLoggedIn
        ) {
            await router.push('/login')
        }

        if (authStore.isLoggedIn && authStore._auth) {
            if (
                config.url?.startsWith(API_ROOT_URL) &&
                !config.url?.startsWith(PUBLIC_API_ROOT_URL)
            ) {
                config.headers['X-Session-Token'] = authStore._auth.session_id
            }
        }

        return config
    }

/**
 * Intercept response for app as well as embed.
 */
export const interceptAnyResponse = async <
    ResponseType extends { status: HttpStatusCode },
>(
    response: ResponseType
) => {
    const toastsStore = useToastsStore()
    if (response.status === HttpStatusCode.TooManyRequests) {
        toastsStore.addMessageTKey('shared.errors.throttled', 'danger')
    } else if (response.status === 403) {
        useAuthStore().logout()
    }
    return response
}

export const interceptAnyError = async (error: AxiosError) => {
    Sentry.captureException(error, {
        tags: {
            current_url: window.location.href,
            requested_url: error.config!.url,
            error_code: error.code,
            error_message: error.message,
        },
    })
    return Promise.reject(error)
}
