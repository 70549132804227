<template>
    <StepContainer :campaign-id="props.campaignId">
        <StepTitle
            :titles="[
                {
                    title: t('user.email.personalize_your_email'),
                    subtitle:
                        t('user.email.your_response_will_be_used') +
                        '\n' +
                        t('user.email.names_not_stored'),
                },
            ]"
        />
        <StepContainerInner>
            <form class="w-full lg:px-[75px]" @submit.prevent="onSubmit(true)">
                <SenderEmailInterpellationQuestions
                    v-if="store.interpellationMode === 'email'"
                    :campaign-id="props.campaignId"
                    @focus="isFocused = true"
                    @blur="isFocused = false"
                />
                <SenderFormCustomQuestions
                    v-if="hasCustomQuestions"
                    :campaign-id="props.campaignId"
                    :errors="validationStarted ? errors : {}"
                    @focus="isFocused = true"
                    @blur="isFocused = false"
                />
                <CenteredContainer class="text-center md:mt-10 max-sm:mb-12">
                    <AVButton
                        type="submit"
                        class="w-full md:w-fit"
                        button-type="primary"
                        :sticky-on-mobile="!isFocused"
                    >
                        {{ t('user.email.create_customized_email') }}
                        <i
                            class="fi fi-rs-arrow-alt-right inline-block text-[22px] h-0 mt-[-10px] ml-[5px] translate-y-[6px]"
                        ></i>
                    </AVButton>
                    <AVButton
                        v-if="hasCustomQuestions"
                        @click="onSubmit(false)"
                        button-type="link"
                        class="text-xs mt-2 md:mt-5"
                    >
                        {{ t('user.email.continue_without_customization') }}
                    </AVButton>
                </CenteredContainer>
            </form>
        </StepContainerInner>
    </StepContainer>
</template>

<script setup lang="ts">
import AVButton from '@/components/forms/AVButton.vue'
import StepTitle from '../StepTitle.vue'
import SenderFormCustomQuestions from './SenderFormCustomQuestions.vue'
import SenderEmailInterpellationQuestions from './SenderEmailInterpellationQuestions.vue'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
    useSendCampaignStore,
    useSendCampaignWizardStore,
} from '@/AppCampaigns/stores'
import {
    buildCustomQuestionPath,
    makeSenderQuestionsValidator,
} from './validation'
import CenteredContainer from '@/components/containers/CenteredContainer.vue'
import StepContainer from '@/components/containers/StepContainer.vue'
import StepContainerInner from '@/components/containers/StepContainerInner.vue'
import { Sender } from '../types'

const { t } = useI18n()
const validationSchema = computed(() =>
    toTypedSchema(
        makeSenderQuestionsValidator(
            store.interpellationMode!,
            store.customQuestions
        )
    )
)

interface Props {
    campaignId: string | null
}
const props = defineProps<Props>()

const store = useSendCampaignStore(props.campaignId)

const wizardStore = useSendCampaignWizardStore(props.campaignId)

const isFocused = ref(false)

const { validate, errors, setValues } = useForm({
    validationSchema,
    initialValues: {
        ...store.sender,
        ...store.customQuestionsAnswers.reduce(
            (acc, answer, index) => {
                acc[buildCustomQuestionPath(index)] = answer
                return acc
            },
            {} as Record<string, string>
        ),
    },
})

const hasCustomQuestions = computed(() => {
    return (
        store.campaign!.custom_questions &&
        store.campaign!.custom_questions.length > 0
    )
})

const validationStarted = ref<boolean>(false)

const onSubmit = async (shouldCustomizeEmail: boolean) => {
    setValues(
        store.customQuestionsAnswers.reduce(
            (acc, answer, index) => {
                acc[buildCustomQuestionPath(index)] = answer
                return acc
            },
            {} as Record<string, string>
        )
    )
    const { valid: isValid, values } = await validate()
    validationStarted.value = true
    if (!shouldCustomizeEmail) {
        store.setSender(null, shouldCustomizeEmail)
        wizardStore.goToNextStep()
    } else if (isValid) {
        store.setSender(values! as Sender, shouldCustomizeEmail)
        wizardStore.goToNextStep()
    }
}

onBeforeMount(() => {
    if (!store.campaign) {
        throw new Error('Store should have am email template set')
    }

    if (!store.interpellationMode) {
        throw new Error('Store should have an interpellation mode set')
    }
})
</script>
