import { Ref, computed, ref } from 'vue'

export const useCopyButton = (contentToCopy: Ref<string>) => {
    const wasClicked = ref(false)

    const copyButtonClassObject = computed(() => ({ copied: wasClicked.value }))

    const copyToClipboard = () => {
        writeTextToClipboard(contentToCopy.value)
        wasClicked.value = true
        setTimeout(() => {
            wasClicked.value = false
        }, 2000)
    }

    return { copyToClipboard, copyButtonClassObject }
}

export const writeTextToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
}
