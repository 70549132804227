<template>
    <div>
        <AVSelectButtons
            :options="interpellationModeOptions"
            :multiple="false"
            name="interpellationMode"
            v-model="interpellationMode"
        />
        <div class="gap-2.5">
            <div
                v-for="(message, index) in store.promptPreview!.prompt"
                :key="index"
                class="leading-1.5 p-4 border-gray-200 bg-gray-100"
            >
                <div class="flex items-center space-x-2 rtl:space-x-reverse">
                    <span class="text-sm font-semibold text-gray-900">{{
                        message.role
                    }}</span>
                </div>
                <pre
                    class="text-sm font-normal py-2.5 text-gray-900 whitespace-pre-wrap"
                >
                    {{ message.content }}
                </pre>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    useCampaignExtraStore,
    useCampaignBackofficeCrudStore,
} from '@/AppBackoffice/campaigns/stores'
import { onMounted, onUnmounted, ref, computed, watch } from 'vue'
import AVSelectButtons from '@/components/forms/AVSelectButtons.vue'
import { useI18n } from 'vue-i18n'
import {
    INTERPELLATION_MODE_OPTIONS,
    InterpellationMode,
} from '@/models/campaigns'

const { t } = useI18n()

const store = useCampaignExtraStore()
const campaignStore = useCampaignBackofficeCrudStore()

const interpellationModeOptions = computed(() =>
    INTERPELLATION_MODE_OPTIONS.filter((item) =>
        campaignStore!.itemData!.interpellation_modes.includes(item.value)
    ).map(({ value, label }) => ({
        value,
        label: t(label),
    }))
)

interface Props {
    customPrompt: string
}

const props = defineProps<Props>()

const interpellationMode = ref<InterpellationMode>()

watch(interpellationMode, (newValue) => {
    store.getPromptPreview(campaignStore!.itemData!.id, newValue as string, {
        content_samples:
            campaignStore.itemData!.interpellations[0].content_samples,
        language: campaignStore!.itemData!.language!,
        custom_questions: campaignStore!.itemData!.custom_questions,
        custom_questions_answers: campaignStore!.itemData!.custom_questions.map(
            (question, idx) => `FAKE ANSWER ${idx + 1}`
        ),
        custom_prompt: props.customPrompt || '',
        interpellation_mode: newValue!,
    })
})

onMounted(() => {
    interpellationMode.value = interpellationModeOptions.value[0].value
})

onUnmounted(() => {
    store.clearPromptPreview()
})
</script>
