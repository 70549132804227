import { ListFiltersDefault } from '@/AppBackoffice/types'
import { defineStore } from 'pinia'
import { useListOperations } from './list'
import { CrudRequests, GenericItem, ListRequest } from './types'
import { ModelId } from '@/models/types'
import { computed } from 'vue'
import { useCrudOperations } from './item'

export const defineListOnlyStore = <
    T extends GenericItem,
    ListFilters extends ListFiltersDefault,
>(
    name: string,
    apiRequests: { listRequest: ListRequest<T, ListFilters> },
    getDefaultFilters: () => ListFilters
) =>
    defineStore(name, () => {
        const listOperations = useListOperations<T, ListFilters>(
            apiRequests.listRequest,
            getDefaultFilters
        )

        // GETTERS
        const isBusy = computed(listOperations.isBusy)
        const getItemById = computed(
            () => (itemId: ModelId) => listOperations.getItemById.value(itemId)
        )
        const meta = computed(() => listOperations.state.value.meta)
        const filters = computed(() => listOperations.state.value.filters)
        const data = computed(listOperations.listData)

        return {
            // STATE
            _listState: listOperations.state,
            _listRequestTracker: listOperations.requestTracker.state,
            // ACTIONS
            runList: listOperations.runList,
            // GETTERS
            data,
            isBusy,
            getItemById,
            meta,
            filters,
        }
    })

export const defineCrudStore = <
    T extends GenericItem,
    WriteT,
    ListFilters extends ListFiltersDefault,
>(
    name: string,
    apiRequests: CrudRequests<T, WriteT> & {
        listRequest: ListRequest<T, ListFilters>
    },
    getDefaultFilters: () => ListFilters
) =>
    defineStore(name, () => {
        const listOperations = useListOperations<T, ListFilters>(
            apiRequests.listRequest,
            getDefaultFilters
        )
        const crudOperations = useCrudOperations<T, WriteT, ListFilters>(
            apiRequests
        )

        // GETTERS
        const isBusy = computed(
            () => crudOperations.isBusy() || listOperations.isBusy()
        )
        const listData = computed(listOperations.listData)
        const listMeta = computed(() => listOperations.state.value.meta)
        const listFilters = computed(() => listOperations.state.value.filters)
        const isListBusy = computed(listOperations.isBusy)
        const itemData = computed(crudOperations.requestTracker.getLoadedData)
        const itemValidationErrors = computed(crudOperations.validationErrors)

        const getItemById = computed(() => (itemId: ModelId) => {
            const found = listOperations.getItemById.value(itemId)
            const loadedData = crudOperations.requestTracker.state.value
                .loadedData as T | null
            if (found !== null) {
                return found
            }

            if (loadedData?.id && loadedData.id === itemId) {
                return crudOperations.requestTracker.getLoadedData()
            }

            return null
        })
        const isListDataEmpty = computed(() => listOperations.isListDataEmpty())

        return {
            // STATE
            _listState: listOperations.state,
            _listRequestTracker: listOperations.requestTracker.state,
            _itemRequestTracker: crudOperations.requestTracker.state,
            // ACTIONS
            runList: listOperations.runList,
            runCreate: crudOperations.runCreate,
            runRead: crudOperations.runRead,
            runUpdate: crudOperations.runUpdate,
            runDelete: crudOperations.runDelete,
            resetItemState: crudOperations.reset,
            resetItemValidationError: crudOperations.resetValidationErrors,
            // GETTERS
            isBusy,
            isListBusy,
            isListDataEmpty,
            listData,
            listMeta,
            listFilters,
            itemData,
            itemValidationErrors,
            getItemById,
        }
    })
