import { components } from '@/schema'
import * as Yup from 'yup'
import { ContentSample } from './content-samples'
import { RecipientPublic } from './recipients'
import { RListPublicEmbed } from './rlists'

export type Interpellation = Omit<
    components['schemas']['Interpellation'],
    'content_samples'
> & {
    content_samples: Array<ContentSample>
}

// export type InterpellationPublic = Omit<
//     components['schemas']['InterpellationPublic'],
//     'content_samples'
// > & {
//     content_samples: Array<ContentSample>
// }

export type InterpellationPublic = Omit<
    components['schemas']['InterpellationPublic'],
    'send_to' | 'cc_list' | 'bcc_list' | 'recipient_lists' | 'content_samples'
> & {
    send_to: Array<RecipientPublic>
    cc_list: Array<RecipientPublic>
    bcc_list: Array<RecipientPublic>
    recipient_lists: Array<RListPublicEmbed>
    content_samples: Array<ContentSample>
}

export type InterpellationWrite = Omit<
    Interpellation,
    'id' | 'created_date' | 'updated_date'
>

export const interpellationValidationBase = {
    id: Yup.string().optional(),
    name: Yup.string().required(),
    description: Yup.string().optional(),
    custom_interpellation_prompt: Yup.string().optional(),
    destination_url: Yup.string().nullable().default(null),
    custom_button_text: Yup.string().nullable().default(null),
    custom_text_before_button: Yup.string().nullable().default(null),
    search_query: Yup.string().nullable().default(null),
}
