<template>
    <div>
        <div class="text-gray-500 mb-2">
            {{ t('backoffice.contentSamples.tweetHelpText') }}
            <code class="bg-gray-200 text-black p-1 rounded-lg">{{
                TWITTER_HANDLES_PLACEHOLDER
            }}</code>
        </div>
        <AVTextarea
            v-bind="textField.props.value"
            v-on="textField.listeners.value"
            placeholder="backoffice.contentSamples.tweetTextPlaceholder"
        />
        <div class="text-gray-500 mt-2 pl-2 text-sm">
            {{ textField.value.value?.length || 0 }} / 280 characters
        </div>
    </div>
</template>
<script setup lang="ts">
import AVTextarea from '@/components/forms/AVTextarea.vue'
import { CampaignWrite } from '@/models/campaigns'
import { ContentSampleTweet } from '@/models/content-samples'
import { makeUseField } from '@/utils/forms'
import { useI18n } from 'vue-i18n'
import { ContentSampleFieldsProps } from './types'
import { TWITTER_HANDLES_PLACEHOLDER } from '../../constants'

const { t } = useI18n()

interface Props extends ContentSampleFieldsProps {}

const props = defineProps<Props>()

const useContentSampleField = makeUseField<CampaignWrite, ContentSampleTweet>(
    props.contentSamplePath
)
const textField = useContentSampleField('text')
</script>
