import { components } from '@/schema'
import * as Yup from 'yup'

export type Recipient = components['schemas']['Recipient']
export type RecipientSimple = components['schemas']['RecipientSimple']

// export type RecipientPublic = components['schemas']['RecipientPublic']

export type RecipientPublic = Omit<
    components['schemas']['RecipientPublic'],
    'email'
> & {
    email?: string
}

export type RecipientPublicEmbed = Omit<
    components['schemas']['RecipientPublic'],
    'email'
> & {
    email?: string
}

export type RecipientWrite = Omit<Recipient, 'id' | 'updated_date'>

export const twitterHandleRegex = /^[a-zA-Z0-9_]*$/

export const recipientValidationBase = {
    id: Yup.string().optional(),
    email: Yup.string().optional(),
    first_name: Yup.string().optional(),
    last_name: Yup.string().optional(),
    gender: Yup.string().oneOf(['M', 'F', 'O', '']).optional(),
    twitter_handle: Yup.string()
        .optional()
        .test(
            'twitter-handle',
            'backoffice.recipients.validation.twitterHandleRegex',
            (value) => !value || value === '' || twitterHandleRegex.test(value)
        )
        .test(
            'twitter-length',
            'backoffice.recipients.validation.twitterHandleLength',
            (value) => !value || (3 <= value.length && value.length <= 15)
        ),
    instagram_handle: Yup.string().optional(),
    facebook_page: Yup.string().optional(),
    address: Yup.string().optional(),
    is_politician: Yup.boolean().optional(),
    birth_date: Yup.string().optional().nullable(),
    constituency_number: Yup.string().optional().nullable(),
    party_name: Yup.string().optional().nullable(),
    photo_url: Yup.string().optional().nullable(),
    constituency: Yup.string().optional().nullable(),
    department_name: Yup.string().optional().nullable(),
    department_number: Yup.string().optional().nullable(),
    party_short_name: Yup.string().optional().nullable(),
    display_title: Yup.string().optional(),
    geo_type: Yup.string().nullable().default(null),
    geo_id: Yup.string().nullable().default(null),
}

export const recipientSimpleValidator: Yup.ObjectSchema<RecipientSimple> =
    Yup.object().shape({
        id: Yup.string().required(),
        email: Yup.string().required(),
        facebook_page: Yup.string().optional(),
        twitter_handle: Yup.string().optional(),
        instagram_handle: Yup.string().optional(),
        first_name: Yup.string().optional(),
        last_name: Yup.string().optional(),
        gender: Yup.string().oneOf(['M', 'F', 'O', '']).optional(),
        photo_url: Yup.string().optional().nullable(),
        constituency: Yup.string().optional().nullable(),
        department_name: Yup.string().optional().nullable(),
        department_number: Yup.string().optional().nullable(),
        party_short_name: Yup.string().optional().nullable(),
        display_title: Yup.string().optional(),
        party_name: Yup.string().optional().nullable(),
        geo_type: Yup.string().nullable().default(null),
        geo_id: Yup.string().nullable().default(null),
    })

export const recipientDisplay = (recipient: RecipientPublic): string => {
    if (recipient.first_name && recipient.last_name) {
        return `${recipient.first_name} ${recipient.last_name}`
    }
    if (recipient.email) {
        return recipient.email
    }
    if (recipient.last_name) {
        return recipient.last_name
    }
    throw new Error('Recipient has no displayable field')
}

export const areSameRecipients = (r1: RecipientPublic, r2: RecipientPublic) => {
    const keys1 = new Set(Object.keys(r1))
    const keys2 = new Set(Object.keys(r2))
    if (
        !(
            keys1.size === keys2.size &&
            [...keys1].every((item) => keys2.has(item))
        )
    ) {
        return false
    }
    if (r1.email && r2.email) {
        return r1.email === r2.email
    } else if (r1.twitter_handle && r2.twitter_handle) {
        return r1.twitter_handle === r2.twitter_handle
    } else if (r1.facebook_page && r2.facebook_page) {
        return r1.facebook_page === r2.facebook_page
    } else if (r1.instagram_handle && r2.instagram_handle) {
        return r1.instagram_handle === r2.instagram_handle
    } else {
        throw new Error(
            `Could not compare recipients ${JSON.stringify(r1)} and ${JSON.stringify(r2)}`
        )
    }
}
