import { API_ROOT_URL } from '@/constants'
import { User, LoginResponse, Email } from '@/models/user'
import axios from 'axios'
import { ChangePassword } from '@/models/user'

// Auth calls should not be intercepted by the interceptor,
// otherwise we will end up in an infinite loop.
const uninterceptedAxiosInstance = axios.create({
    // Never throw an error when receiving unexpected HTTP status code
    // We handle all status ourselves in the code.
    validateStatus: () => true,
})

export const loginRequest = async (username: string, password: string) => {
    const data = username.includes('@')
        ? { email: username, password }
        : { username, password }

    return uninterceptedAxiosInstance.post<LoginResponse>(
        API_ROOT_URL + '/activoice/accounts-hl/app/v1/auth/login',
        data
    )
}

export const verifyEmailRequest = async (key: string, sessionId: string) => {
    return uninterceptedAxiosInstance.post<LoginResponse>(
        API_ROOT_URL + '/activoice/accounts-hl/app/v1/auth/email/verify',
        {
            key,
        },
        {
            headers: {
                'X-Session-Token': sessionId,
            },
        }
    )
}

export const listEmailsRequest = async (sessionId: string) =>
    axios.get<{ data: Array<Email> }>(
        API_ROOT_URL + '/activoice/accounts-hl/app/v1/account/email',
        {
            headers: {
                'X-Session-Token': sessionId,
            },
        }
    )

export const addEmailRequest = async (email: string, sessionId: string) =>
    axios.post(
        API_ROOT_URL + '/activoice/accounts-hl/app/v1/account/email',
        { email },
        {
            headers: {
                'X-Session-Token': sessionId,
            },
        }
    )

export const emailValidationRequest = async (
    email: string,
    sessionId: string
) =>
    axios.put(
        API_ROOT_URL + '/activoice/accounts-hl/app/v1/account/email',
        { email },
        {
            headers: {
                'X-Session-Token': sessionId,
            },
        }
    )

export const setPrimaryEmailRequest = async (
    email: string,
    sessionId: string
) =>
    axios.patch(
        API_ROOT_URL + '/activoice/accounts-hl/app/v1/account/email',
        { email, primary: true },
        {
            headers: {
                'X-Session-Token': sessionId,
            },
        }
    )

export const removeEmailRequest = async (email: string, sessionId: string) =>
    axios.delete(API_ROOT_URL + '/activoice/accounts-hl/app/v1/account/email', {
        data: { email },
        headers: {
            'X-Session-Token': sessionId,
        },
    })

export const readUser = async () =>
    axios.get<User>(`${API_ROOT_URL}/activoice/user/`)

export const putUser = async (user: User) =>
    axios.put(`${API_ROOT_URL}/activoice/user/`, user)

export const updatePasswordRequest = async (
    changePasswordData: ChangePassword
) => {
    return axios.put(
        `${API_ROOT_URL}/activoice/update-password/`,
        changePasswordData
    )
}

export const startResetPasswordRequest = async (email: string) =>
    axios.post(
        `${API_ROOT_URL}/activoice/accounts-hl/app/v1/auth/password/request`,
        { email }
    )

export const resetPasswordRequest = async (password: string, token: string) =>
    axios.post(
        `${API_ROOT_URL}/activoice/accounts-hl/app/v1/auth/password/reset`,
        { password, key: token }
    )
