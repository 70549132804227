<template>
    <StepContainerInner class="space-y-4 relative">
        <div
            v-if="recipientSearchStore.searchState !== GeolocState.START"
            class="absolute top-5 left-5 cursor-pointer text-primary"
            @click="recipientSearchStore.reset"
        >
            <i
                class="fi fi-rs-angle-left mr-1 translate-y-0.5 inline-block text-sm"
            ></i>
            <span class="text-sm">{{ t('user.common.back') }}</span>
        </div>
        <form @submit.prevent="onAddressFormSubmit()" class="space-y-4">
            <StepInstructions class="font-[600]">
                {{ t('user.campaign.geolocation_enter_your_address') }}
            </StepInstructions>

            <AVInput
                v-model="address"
                name="address"
                prefix-img="rs-marker"
                :placeholder="
                    t('user.campaign.geolocation_address_placeholder')
                "
            />

            <AVButton
                class="w-full !mt-2"
                type="submit"
                :button-type="
                    recipientSearchStore.searchState ===
                    GeolocState.SELECT_RECIPIENT
                        ? 'outline-primary'
                        : 'primary'
                "
            >
                {{ t('user.actions.search') }}
            </AVButton>

            <div
                v-if="
                    recipientSearchStore.searchState ===
                    GeolocState.VALIDATE_ADDRESS
                "
                class="space-y-4 text-center"
            >
                <div class="text-sm text-gray-800">
                    {{ t('user.campaign.geolocation_select_your_address') }}
                </div>
                <AVSelectButtons
                    class="!mt-2 text-left"
                    :vertical="true"
                    :multiple="false"
                    :options="
                        recipientSearchStore.addressSearchResults.map(
                            (address, idx) => ({
                                value: idx,
                                label: address.properties.street
                                    ? address.properties.label
                                    : `${address.properties.label} (${address.properties.postcode})`,
                            })
                        )
                    "
                    @update:model-value="
                        recipientSearchStore.selectAddress(
                            $event as number,
                            store.campaignGeoType!,
                            store.campaign!.is_strictly_geolocated || false
                        )
                    "
                    v-bind:name="''"
                    :model-value="undefined"
                />
            </div>
        </form>

        <div
            v-if="
                recipientSearchStore.searchState ===
                GeolocState.SELECT_RECIPIENT
            "
        >
            <div
                v-if="recipientsInCampaign.length > 0"
                class="flex flex-col space-y-2"
            >
                <div class="text-sm text-gray-800">
                    {{ t('user.campaign.geolocation_select_recipient') }}
                </div>
                <RecipientSelectorWithInfos
                    v-for="(recipient, index) in recipientsInCampaign"
                    :key="index"
                    :recipient="recipient as RecipientSimple"
                    :contactInfosAvailable="
                        store.getInterpellationModesAvailableForRecipient(
                            recipient
                        ).length > 0
                    "
                    :class="{
                        'bg-gray-100 rounded-lg cursor-default':
                            store.getInterpellationModesAvailableForRecipient(
                                recipient
                            ).length === 0,
                        'cursor-pointer':
                            store.getInterpellationModesAvailableForRecipient(
                                recipient
                            ).length > 0,
                    }"
                    @click="onChooseRecipient(recipient)"
                />
            </div>
            <div v-else-if="recipientSearchStore.hasErrored">
                {{ t('user.campaign.geolocation_load_error') }}
            </div>
            <div
                v-else-if="
                    isRequestDone &&
                    recipientSearchStore.geoLocationSearchResults &&
                    recipientsInCampaign.length === 0
                "
                id="no-recipient"
                v-html="
                    store.campaign!.alternative_text_when_no_recipients === ''
                        ? t('user.campaign.geolocation_no_result')
                        : store.campaign!.alternative_text_when_no_recipients
                "
            ></div>
        </div>

        <div
            v-if="recipientSearchStore.isLoading"
            class="flex flex-col justify-center items-center"
        >
            <AVLoader />
            {{ t('user.campaign.geolocation_loading') }}
        </div>
    </StepContainerInner>
</template>
<script setup lang="ts">
import { computed, onBeforeMount, ref, watch } from 'vue'
import { ModelId } from '@/models/types'
import { GeolocState } from '@/AppCampaigns/types'
import {
    useRecipientSearchStore,
    useSendCampaignStore,
    useSendCampaignWizardStore,
} from '../../stores'
import { RecipientPublic, RecipientSimple } from '@/models/recipients'
import StepInstructions from '../StepInstructions.vue'
import StepContainerInner from '@/components/containers/StepContainerInner.vue'
import AVInput from '@/components/forms/AVInput.vue'
import AVButton from '@/components/forms/AVButton.vue'
import AVLoader from '@/components/AVLoader.vue'
import AVSelectButtons from '@/components/forms/AVSelectButtons.vue'
import { useI18n } from 'vue-i18n'
import RecipientSelectorWithInfos from '@/AppCampaigns/components/RecipientSelectorWithInfos.vue'
import { selectInterpellationRecipients } from '@/models/selectors'
import { useToastsStore } from '@/stores/toasts'

const { t } = useI18n()

interface Props {
    campaignId: string | null
}
const props = defineProps<Props>()

const recipientSearchStore = useRecipientSearchStore()
const wizardStore = useSendCampaignWizardStore(props.campaignId)
const store = useSendCampaignStore(props.campaignId)
const usetoastsStore = useToastsStore()

const address = ref<string | null>(null)
const isRequestDone = ref(false)

const rlistIds = computed<Array<ModelId>>(() =>
    store.campaign!.interpellations.flatMap((interpellation) =>
        interpellation.recipient_lists
            .map((rlist) => rlist.id)
            .filter((id): id is string => !!id)
    )
)

const onAddressFormSubmit = async () => {
    if (!address.value) {
        return
    }
    recipientSearchStore.reset()
    try {
        await recipientSearchStore.searchAddress(
            address.value,
            rlistIds.value,
            store.campaignGeoType!,
            store.campaign!.is_strictly_geolocated || false
        )
        isRequestDone.value = true
    } catch (e) {
        usetoastsStore.addMessage('An error occured, try again.', 'danger')
    }
}

const recipientsInCampaign = computed(() => {
    const isPlanBAvailable =
        store.campaign!.alternative_text_when_no_recipients !== ''
    const geoLocationResults = recipientSearchStore.geoLocationSearchResults

    if (!geoLocationResults?.length) {
        return []
    }

    // Get all recipients in the campaign
    const allRecipients = store
        .campaign!.interpellations.map((x) =>
            selectInterpellationRecipients(x, {})
        )
        .flat()

    // Iterate over geoLocationResults and return the first matching recipient found
    for (const geoResult of geoLocationResults) {
        const matchingRecipient = allRecipients.find(
            (recipient) => recipient.geo_id === geoResult.geo_id
        )
        if (!isPlanBAvailable && matchingRecipient) {
            return [matchingRecipient] // Return as soon as a match is found
        }
        if (
            isPlanBAvailable &&
            matchingRecipient &&
            store.getInterpellationModesAvailableForRecipient(matchingRecipient)
                .length > 0
        ) {
            return [matchingRecipient] // Return as soon as a match is found
        }
    }

    // If no matching recipient is found, return an empty array
    return []
})

const onChooseRecipient = (recipient: RecipientPublic) => {
    if (
        store.getInterpellationModesAvailableForRecipient(recipient).length ===
        0
    ) {
        return
    }
    wizardStore.$reset(null, 'StepGeolocation')
    store.setRecipient(recipient)
    if (store.interpellationModesAvailableForSelectedRecipients.length == 1) {
        store.setInterpellationMode(
            store.interpellationModesAvailableForSelectedRecipients[0]
        )
    }
    wizardStore.goToNextStep()
}

watch(address, () => {
    if (isRequestDone.value) {
        isRequestDone.value = false
    }
})

onBeforeMount(() => {
    if (!store.campaign) {
        throw new Error('Store should have a campaign set')
    }
})
</script>
<style>
#no-recipient > p a {
    text-decoration: underline !important;
    color: var(--color-primary) !important;
}
</style>
