<template>
    <div>
        <LoaderWithOverlay v-if="crudStore.isBusy" />

        <div class="container mx-auto px-4 py-8">
            <RouterView></RouterView>
            <ListHeader
                titleTKey="backoffice.generatedContents.generatedContents"
                descriptionTKey="backoffice.generatedContents.description"
            />
            <div>
                <PaginationAndFilter
                    v-if="crudStore.meta"
                    :list-filters="crudStore.filters"
                    :list-meta="crudStore.meta"
                    route-name="generated_contents"
                />
                <ListTable v-if="cells" :columns="columns" :rows="cells" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import LoaderWithOverlay from '@/components/LoaderWithOverlay.vue'
import ListHeader from '@/AppBackoffice/components/ListHeader.vue'
import ListTable from '@/AppBackoffice/components/ListTable/ListTable.vue'
import { RowDefinition } from '@/AppBackoffice/components/ListTable/types'
import { onBeforeMountOrPropChange } from '@/utils/components'
import PaginationAndFilter from '@/AppBackoffice/components/PaginationAndFilter.vue'
import { RouterView } from 'vue-router'
import { useGeneratedContentsBackofficeCrudStore } from './stores'
import { computed } from 'vue'
import { GeneratedContent } from '@/models/generated-content'
import { ColumnDefinition } from '../components/ListTable/types'
import mapValues from 'lodash/mapValues'
import { INTERPELLATION_MODES_ICONS } from '../campaigns/constants'
import { GeneratedContentListParams } from './requests'

export interface Props extends GeneratedContentListParams {}

const props = defineProps<Props>()
const crudStore = useGeneratedContentsBackofficeCrudStore()
const columns = computed<Array<ColumnDefinition<GeneratedContent>>>(() => [
    {
        name: 'campaign_name',
        tKey: 'backoffice.generatedContents.columns.campaign_name',
    },
    {
        name: 'interpellation_name',
        tKey: 'backoffice.generatedContents.columns.interpellation_name',
    },
    {
        name: 'content',
        tKey: 'backoffice.generatedContents.columns.content',
    },
    {
        name: 'created_date',
        tKey: 'backoffice.generatedContents.columns.created_date',
    },
    {
        name: 'interpellation_mode',
        tKey: 'backoffice.generatedContents.columns.interpellation_mode',
    },
])

const cells = computed<Array<RowDefinition<GeneratedContent>> | undefined>(() =>
    crudStore.data?.map((generatedContent) => {
        let contentDisplay: string
        switch (generatedContent.content.type) {
            case 'comment':
            case 'tweet':
                contentDisplay = generatedContent.content.text
                break
            case 'email':
                contentDisplay =
                    generatedContent.content.subject +
                    '\n' +
                    generatedContent.content.body
                break
        }
        const row: RowDefinition<GeneratedContent> = {
            route: {
                name: 'generated_contents_read',
                params: {
                    generatedContentId: generatedContent.id,
                },
            },
            cells: {
                ...mapValues(generatedContent, (value) => ({ value })),
                created_date: {
                    value: generatedContent.created_date,
                    valueForDisplay: new Date(generatedContent.created_date),
                },
                content: {
                    value: generatedContent.content,
                    valueForDisplay: contentDisplay,
                },
                interpellation_mode: {
                    value: generatedContent.interpellation_mode,
                    icon: INTERPELLATION_MODES_ICONS[
                        generatedContent.interpellation_mode
                    ],
                },
            },
        }
        return row
    })
)

const onListConfigUpdated = () => {
    crudStore.runList({
        page: props.page,
        created_start: props.created_start,
        created_end: props.created_end,
        campaign_id: props.campaign_id,
        interpellation_id: props.interpellation_id,
        interpellation_mode: props.interpellation_mode,
    })
}

onBeforeMountOrPropChange(
    [
        () => props.page,
        () => props.created_start,
        () => props.created_end,
        () => props.campaign_id,
        () => props.interpellation_id,
        () => props.interpellation_mode,
    ],
    onListConfigUpdated
)
</script>
