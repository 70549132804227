<template>
    <div class="container-message-outter md:max-h-full md:overflow-auto w-full">
        <div class="ContainerMessageInner p-2 lg:p-5">
            <slot></slot>
        </div>
    </div>
</template>
<style scoped>
.container-message-outter {
    box-shadow: 0px 0px 4px 0px var(--color-shadow);
}

.ContainerMessageInner {
    font-size: 13px;
    border-radius: 6px;
    border: 0.5px solid var(--Light-theme-Medium-gray, var(--color-light-gray));
    background: var(--Light-theme-White, var(--color-white));
    box-shadow: 0px 0px 4px 0px var(--color-shadow);
    white-space: pre-line;
    max-width: 100%;
}

@media only screen and (max-width: 600px) {
    .ContainerMessageInner {
        padding: 15px;
        line-height: 1.3em;
    }
}
</style>
