import { HttpStatusCode } from 'axios'
import { useCrudShared } from './shared'
import { useRequestTracker } from '../../utils/request-tracker'
import { computed, ref, UnwrapRef } from 'vue'
import { ListFiltersDefault, ListMeta } from '@/AppBackoffice/types'
import { ModelId } from '@/models/types'
import { ListResponseData, RequestStatus } from '@/types'
import { GenericItem, ListRequest } from './types'

type FilterGenerator<T extends ListFiltersDefault> = () => T

interface ListState {
    readonly meta: ListMeta | null
    readonly filters: ListFiltersDefault
}

export type ListOperations<
    T extends GenericItem,
    ListFilters extends ListFiltersDefault,
> = ReturnType<typeof useListOperations<T, ListFilters>>

export const useListOperations = <
    T extends GenericItem,
    ListFilters extends ListFiltersDefault,
>(
    runRequest: ListRequest<T, ListFilters>,
    getDefaultFilters: FilterGenerator<ListFilters>
) => {
    type _ListState = ListState
    type _ListFilters = ListFilters & ListFiltersDefault

    const requestTracker = useRequestTracker<ListResponseData<T>>()

    const { _addErrorToast } = useCrudShared()

    // STATE
    const state = ref<_ListState>({
        meta: null,
        filters: getDefaultFilters(),
    })

    // ACTIONS
    const runList = async (params?: Partial<_ListFilters>) => {
        // List config and filters are mixed up in `params`
        // so we need to separate them to store them.
        // TODO : Refactor this to avoid having to do this (maybe
        // put all together in same object ?)
        const newFilters: _ListFilters = {
            ...(state.value.filters as _ListFilters),
            ...(params || {}),
        }
        state.value = {
            ...state.value,
            filters: newFilters as UnwrapRef<_ListFilters>,
        }

        const requestStatus = await requestTracker.runRequest(
            runRequest,
            HttpStatusCode.Ok,
            [
                {
                    ...newFilters,
                },
            ]
        )

        if (requestStatus === RequestStatus.SUCCESS) {
            const loadedData = requestTracker.getLoadedData()!
            state.value = {
                ...state.value,
                filters: {
                    ...state.value.filters,
                    filter: newFilters.filter,
                    page: loadedData.pagination.current_page,
                } as UnwrapRef<_ListFilters>,
                meta: {
                    listLength: loadedData.pagination.count,
                    totalPages: Math.ceil(
                        loadedData.pagination.count /
                            loadedData.pagination.page_size
                    ),
                },
            }
        } else if (requestStatus === RequestStatus.ERROR) {
            if (
                requestTracker.state.value.error!.status !==
                HttpStatusCode.Unauthorized
            ) {
                _addErrorToast()
            }
        }

        return requestStatus
    }

    // GETTERS
    const isListDataEmpty = () =>
        isListDataLoaded() === true && listData()!.length === 0

    const isListDataLoaded = () => listData() !== null

    const listData = () =>
        requestTracker.state.value.loadedData
            ? requestTracker.state.value.loadedData.results
            : null

    const getItemById = computed(() => (itemId: ModelId) => {
        const loadedData = requestTracker.getLoadedData()
        if (loadedData) {
            const result = loadedData.results.find(
                (item) => item.id === itemId
            ) as T | undefined
            if (result) {
                return result
            }
        }
        return null
    })

    return {
        // STATE
        state,
        requestTracker,
        // ACTIONS
        runList,
        // GETTERS
        isBusy: requestTracker.isRequestInProgress,
        isListDataEmpty,
        isListDataLoaded,
        listData,
        getItemById,
    }
}
