<template>
    <StepContainer :campaign-id="props.campaignId">
        <PlaceSelection :campaign-id="props.campaignId" />
    </StepContainer>
</template>
<script setup lang="ts">
import { onBeforeMount, ref, watch } from 'vue'
import { useRecipientSearchStore, useSendCampaignStore } from '../../stores'
import StepContainer from '@/components/containers/StepContainer.vue'
import PlaceSelection from './PlaceSelection.vue'

interface Props {
    campaignId: string | null
}
const props = defineProps<Props>()

const recipientSearchStore = useRecipientSearchStore()
const store = useSendCampaignStore(props.campaignId)

const address = ref<string | null>(null)
const isRequestDone = ref(false)

watch(address, () => {
    if (isRequestDone.value) {
        isRequestDone.value = false
        recipientSearchStore.reset()
    }
})

onBeforeMount(() => {
    if (!store.campaign) {
        throw new Error('Store should have a campaign set')
    }
})
</script>
