<template>
    <header
        class="w-full relative text-center py-4 md:py-3 bg-white border-b border-gray-200 font-semibold text-xs md:text-sm md:rounded-t-lg"
    >
        <button
            v-if="wizardStore.currentStepIndex > 0"
            class="hidden md:block py-3 absolute top-0 left-0 font-normal text-xs pl-4"
            @click="onClickReturn"
        >
            <i class="fi fi-rs-angle-left mr-1 relative top-1"></i>
            <span class="underline">{{ t('embed.actions.return') }}</span>
        </button>
        {{ props.title }}
        <slot></slot>
    </header>
</template>
<script setup lang="ts">
import {
    useSendCampaignStore,
    useSendCampaignWizardStore,
} from '@/AppCampaigns/stores'
import { useI18n } from 'vue-i18n'

interface Props {
    title: string
    campaignId: string | null
}
const props = defineProps<Props>()

const store = useSendCampaignStore(props.campaignId)
const wizardStore = useSendCampaignWizardStore(props.campaignId)
const { t } = useI18n()

const onClickReturn = () => {
    if (store.isSendDone) {
        store.setIsSendDone(false)
    } else {
        wizardStore.goToPreviousStep()
    }
}
</script>
