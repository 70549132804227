import { GeneratedContentListParams, listGeneratedContents } from './requests'
import { defineListOnlyStore } from '@/stores/crud'
import { GeneratedContent } from '@/models/generated-content'

export const useGeneratedContentsBackofficeCrudStore = defineListOnlyStore<
    GeneratedContent,
    GeneratedContentListParams
>(
    'Backoffice:GeneratedContents:Crud',
    {
        listRequest: listGeneratedContents,
    },
    () => ({
        page: 1,
    })
)
