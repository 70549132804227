<template>
    <RouterLink
        :to="{
            name: 'send_campaign',
            params: {
                campaignId: campaignLinkId,
            },
        }"
        class="text-primary"
        target="_blank"
    >
        <slot>Open campaign</slot>
    </RouterLink>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { RouterLink } from 'vue-router'

interface Props {
    campaignId: string
    campaignSlug?: string | null
}

const props = defineProps<Props>()

// Computed campaignId based on slug or id
const campaignLinkId = computed(() =>
    props.campaignSlug ? props.campaignSlug : props.campaignId
)
</script>
