<template>
    <ContainerMain class="text-center">
        <MessagePreview
            :message-text="generatedMessageText"
            :is-streaming="!store.isStreamingIdle"
            :has-copy-button="false"
        />

        <TransitionExpands>
            <div
                v-show="store.isStreamingIdle"
                class="flex flex-col items-center overflow-hidden"
            >
                <ButtonGenerateOther
                    label-t-key="user.campaign.tweet_generate_other"
                    @generate-message="store.reformulateContentSample()"
                />
                <MessageInstructions
                    instructions-t-key="user.campaign.tweet_instructions"
                />
                <SendButtons
                    :send-links="sendLinks"
                    @send-button-clicked="() => emit('sendButtonClicked')"
                    :campaign-id="props.campaignId"
                />
            </div>
        </TransitionExpands>
    </ContainerMain>
</template>
<script setup lang="ts">
import { useSendCampaignStore } from '@/AppCampaigns/stores'
import { computed } from 'vue'
import { ContentSampleTweet } from '@/models/content-samples'
import MessagePreview from './MessagePreview.vue'
import ContainerMain from '../ContainerMain.vue'
import TransitionExpands from '../TransitionExpands.vue'
import ButtonGenerateOther from './ButtonGenerateOther.vue'
import MessageInstructions from './MessageInstructions.vue'
import { TWITTER_HANDLES_PLACEHOLDER } from '@/AppBackoffice/campaigns/constants'
import shuffle from 'lodash/shuffle'
import SendButtons from './SendButtons.vue'
import { useI18n } from 'vue-i18n'
import { SendButtonsEmits } from '../../types'

interface Emits extends SendButtonsEmits {}

const emit = defineEmits<Emits>()

const TWITTER_HANDLES_COUNT = 3

interface Props {
    campaignId: string | null
}
const props = defineProps<Props>()

const store = useSendCampaignStore(props.campaignId)
const { t } = useI18n()

const generatedMessageText = computed(() =>
    store.generatedContent
        ? (store.generatedContent as ContentSampleTweet).text.replace(
              TWITTER_HANDLES_PLACEHOLDER,
              selectedTwitterHandles.value
                  .map((handle) => `@${handle}`)
                  .join(' ')
          )
        : null
)

const selectedTwitterHandles = computed(() =>
    shuffle(store.recipients)
        .slice(0, TWITTER_HANDLES_COUNT)
        .map((recipient) => recipient.twitter_handle)
)

const sendLinks = computed(() => [
    {
        href: `https://x.com/intent/tweet?text=${encodeURIComponent(generatedMessageText.value ?? '')}`,
        label: t('user.campaign.send_tweet'),
    },
])
</script>
