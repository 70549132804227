import { defineStore } from 'pinia'
import {
    loginRequest,
    updatePasswordRequest,
    readUser,
    putUser,
    verifyEmailRequest,
    listEmailsRequest,
    addEmailRequest,
    removeEmailRequest,
    setPrimaryEmailRequest,
    emailValidationRequest,
    startResetPasswordRequest,
    resetPasswordRequest,
} from './requests'
import { HttpStatusCode } from 'axios'
import { RequestStatus } from '@/types'
import { ChangePassword, Email, LoginResponse, User } from '@/models/user'
import { computed, ref } from 'vue'
import { castToDeepReadOnly } from '@/utils/types'
import { useCrudOperations } from '@/stores/crud/item'
import { useRequestTracker } from '@/utils/request-tracker'
import { TOKEN_EXPIRY_MS } from './constants'

interface AuthState {
    expirationTimestamp: number
    session_id: string
}

export type AuthStore = ReturnType<typeof useAuthStore>

export const useAuthStore = defineStore(
    'Backoffice:User:Auth',
    () => {
        const tokensRequestTracker = useRequestTracker<LoginResponse>()
        const listEmailsRequestTracker = useRequestTracker<{
            data: Array<Email>
        }>()
        const statusRequestTracker = useRequestTracker()
        // STATE
        const _auth = ref<null | AuthState>(null)

        // ACTIONS
        // Login / Logout
        const login = async (username: string, password: string) => {
            const requestStatus = await tokensRequestTracker.runRequest(
                loginRequest,
                HttpStatusCode.Ok,
                [username, password]
            )
            if (requestStatus === RequestStatus.SUCCESS) {
                const meta = tokensRequestTracker.getLoadedData()!.meta
                _setTokens(meta.session_token)
            }
            return requestStatus
        }

        const logout = () => {
            _auth.value = null
        }

        const startResetPassword = async (email: string) => {
            const requestStatus = await statusRequestTracker.runRequest(
                startResetPasswordRequest,
                HttpStatusCode.Ok,
                [email]
            )
            return requestStatus
        }

        const updatePassword = async (changePasswordData: ChangePassword) => {
            const requestStatus = await statusRequestTracker.runRequest(
                updatePasswordRequest,
                HttpStatusCode.Ok,
                [changePasswordData]
            )
            return requestStatus
        }

        const resetPassword = async (password: string, token: string) => {
            const requestStatus = await statusRequestTracker.runRequest(
                resetPasswordRequest,
                HttpStatusCode.Unauthorized,
                [password, token]
            )
            return requestStatus
        }

        // Email
        const verifyEmail = async (key: string) => {
            if (_auth.value === null) {
                throw new Error('Session token is not set')
            }
            const requestStatus = await tokensRequestTracker.runRequest(
                verifyEmailRequest,
                HttpStatusCode.Ok,
                [key, _auth.value.session_id]
            )

            return requestStatus
        }

        const listEmails = async () => {
            if (_auth.value === null) {
                throw new Error('Session token is not set')
            }
            const requestStatus = await listEmailsRequestTracker.runRequest(
                listEmailsRequest,
                HttpStatusCode.Ok,
                [_auth.value.session_id]
            )
            return requestStatus
        }

        const addEmail = async (email: string) => {
            if (_auth.value === null) {
                throw new Error('Session token is not set')
            }
            const requestStatus = await listEmailsRequestTracker.runRequest(
                addEmailRequest,
                HttpStatusCode.Ok,
                [email, _auth.value.session_id]
            )
            return requestStatus
        }

        const removeEmail = async (email: string) => {
            if (_auth.value === null) {
                throw new Error('Session token is not set')
            }
            const requestStatus = await listEmailsRequestTracker.runRequest(
                removeEmailRequest,
                HttpStatusCode.Ok,
                [email, _auth.value.session_id]
            )
            return requestStatus
        }

        const setPrimary = async (email: string) => {
            if (_auth.value === null) {
                throw new Error('Session token is not set')
            }
            const requestStatus = await statusRequestTracker.runRequest(
                setPrimaryEmailRequest,
                HttpStatusCode.Ok,
                [email, _auth.value.session_id]
            )
            return requestStatus
        }

        const startEmailVerification = async (key: string) => {
            if (_auth.value === null) {
                throw new Error('Session token is not set')
            }
            const requestStatus = await statusRequestTracker.runRequest(
                emailValidationRequest,
                HttpStatusCode.Ok,
                [key, _auth.value.session_id]
            )
            if (requestStatus === RequestStatus.SUCCESS) {
                _setTokens(_auth.value.session_id)
            } else {
                logout()
            }
            return requestStatus
        }

        const _setTokens = (sessionToken: string) => {
            _auth.value = {
                ..._auth.value,
                expirationTimestamp: Date.now() + TOKEN_EXPIRY_MS,
                session_id: sessionToken,
            }
        }

        // GETTERS
        const isLoggedIn = computed((): boolean => {
            if (
                _auth.value &&
                Object.keys(_auth.value).some(
                    (key) =>
                        !['expirationTimestamp', 'session_id'].includes(key)
                )
            ) {
                logout()
                return false
            }
            return _auth.value !== null
        })

        const isBusy = computed(tokensRequestTracker.isRequestInProgress)

        const isAccessExpiring = computed((): boolean | null => {
            if (_auth.value === null) {
                throw new Error('Expiration timestamp is null')
            }
            return _auth.value.expirationTimestamp - Date.now() < 30 * 1000
        })

        return castToDeepReadOnly({
            _auth: _auth,
            tokensRequestTracker,
            listEmailsRequestTracker,
            statusRequestTracker,
            login,
            verifyEmail,
            logout,
            updatePassword,
            listEmails,
            addEmail,
            removeEmail,
            setPrimary,
            startEmailVerification,
            startResetPassword,
            resetPassword,
            isLoggedIn,
            isBusy,
            isAccessExpiring,
        })
    },
    // Use lib `pinia-plugin-persistedstate` to persist `auth` from the state.
    {
        persist: {
            paths: ['_auth'],
        },
    }
)

export const useUserBackofficeCrudStore = defineStore(
    'Backoffice:User:Crud',
    () => {
        const crudOperations = useCrudOperations<User, User>({
            readRequest: readUser,
            updateRequest: (_: string, item: User) => putUser(item),
        })

        const user = computed(crudOperations.requestTracker.getLoadedData)

        return {
            // STATE
            _requestTracker: crudOperations.requestTracker.state,
            // ACTIONS
            readUser: crudOperations.runRead,
            updateUser: crudOperations.runUpdate,
            // GETTERS
            user,
        }
    }
)
