<template>
    <div class="flex flex-col">
        <div>
            <label
                :class="{
                    'inline mb-2 mr-1 text-[15px] font-medium lg:font-normal text-gray-900': true,
                    'text-red-700 ': errorTKey,
                }"
                v-if="label"
                :for="name"
                >{{ label }}</label
            >
            <AVInfoTooltip
                v-if="tooltipMessage"
                :id="name"
                :message="tooltipMessage"
            />
        </div>
        <div class="relative flex-1">
            <span
                v-if="prefix || prefixImg"
                class="text-neutral-400 absolute left-[0.5em] top-[50%] -translate-y-1/2"
            >
                <template v-if="prefix">
                    {{ prefix }}
                </template>
                <template v-else-if="prefixImg">
                    <i :class="`fi fi-${prefixImg}`"></i>
                </template>
            </span>
            <input
                :class="{
                    'border border-gray-300 text-gray-900 text-[13px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5': true,
                    'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500':
                        errorTKey,
                }"
                :style="{
                    'padding-left': prefix
                        ? `${prefix.length + 1}em`
                        : prefixImg
                          ? '2.3em'
                          : '',
                    display: type === 'color' ? 'none' : '',
                }"
                :name="name"
                :id="name"
                :type="type"
                :value="modelValue"
                :placeholder="props.placeholder"
                @input="onChange"
                @blur="onBlur"
                @focus="onFocus"
            />
            <div
                :style="{
                    display: type === 'color' ? 'block' : 'none',
                    background: type === 'color' ? modelValue || 'white' : '',
                    height: '30px',
                    width: '30px',
                    borderRadius: '50%',
                }"
                @click="clickOnInput"
            ></div>
        </div>
        <AVError v-if="errorTKey" :error-t-key="errorTKey" />
    </div>
</template>

<script setup lang="ts">
import AVError from '@/components/forms/AVError.vue'
import AVInfoTooltip from '../Tooltip/AVInfoTooltip.vue'
import { FormFieldEmits, FormFieldProps } from '@/utils/forms'
import { getCurrentInstance } from 'vue'

interface Props extends FormFieldProps<string | null | undefined> {
    placeholder?: string
    type?: string
    prefix?: string
    prefixImg?: string
    tooltipMessage?: string
}

interface Emits extends FormFieldEmits<string> {
    (event: 'blur', domEvent: Event): void
    (event: 'focus', domEvent: Event): void
    (event: 'update:modelValue', value: string): void
}

const props = withDefaults(defineProps<Props>(), {
    type: 'text',
    placeholder: '',
    name: () => {
        const instance = getCurrentInstance()
        return 'input-'.concat(instance ? String(instance?.uid) : '')
    },
})

const emit = defineEmits<Emits>()

const onChange = (event: Event) => {
    emit('update:modelValue', (event.target as HTMLSelectElement).value)
}

const onBlur = (event: Event) => {
    emit('blur', event)
}

const onFocus = (event: Event) => {
    emit('focus', event)
}

const clickOnInput = () => {
    document.getElementById(props.name)!.click()
}
</script>
