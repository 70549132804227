import { TKey } from './i18n'

export type AppKey = 'AppBackoffice' | 'AppCampaigns'

export enum RequestStatus {
    INIT = 0,
    IN_PROGRESS = 1,
    SUCCESS = 2,
    ERROR = 3,
    ABORTED = 4,
}

export interface ListResponseData<T> {
    results: Array<T>
    pagination: {
        readonly next: string | null
        readonly previous: string | null
        readonly page_size: number
        readonly current_page: number
        readonly count: number
    }
}

export type RouteName = string

export type EmailClient =
    | 'gmail'
    | 'yahoo'
    | 'outlook'
    | 'icloud'
    | 'mail'
    | 'aol'
    | 'zoho'
    | 'protonmail'

export type Message = {
    id: string
    type: 'success' | 'danger' | 'info'
    message: string | null
    messageTKey: TKey | null
    timeout: number | null
}

export type QueryParamValue =
    | string
    | symbol
    | number
    | Date
    | null
    | undefined
    | boolean

export type QueryParams<K extends string> = Record<K, QueryParamValue>

/**
 * Helper type to extract query parameters from the generated schema file.
 */
export type ExtractQueryParams<
    QueryParamsRaw extends { [k: string]: string | number } | undefined,
> = { [k in keyof NonNullable<QueryParamsRaw>]?: string }
