<template>
    <a
        v-if="href"
        :href="href"
        target="_blank"
        class="flex justify-center items-center w-[3em] h-[3em] rounded-full bg-primary"
    >
        <i
            :class="`flex justify-center items-center text-[1.5rem] fi ${INTERPELLATION_MODES_ICONS[interpellationMode]} text-white`"
        ></i>
    </a>
    <button
        v-else
        class="flex justify-center items-center w-[3em] h-[3em] rounded-full bg-primary"
        @click="copyToClipboard"
    >
        <i
            v-if="!copyClicked"
            class="fi fi-rs-copy-alt text-sm bg-white text-primary rounded-full px-[5px] py-[3px]"
        ></i>
        <i
            v-else
            class="fi fi-rs-check text-sm bg-white text-primary rounded-full px-[5px] py-[3px]"
        ></i>
    </button>
</template>
<script setup lang="ts">
import { INTERPELLATION_MODES_ICONS } from '@/AppBackoffice/campaigns/constants'
import { InterpellationMode } from '@/models/campaigns'
import { ref } from 'vue'

interface Props {
    interpellationMode: InterpellationMode
    href?: string
    toCopy?: string
}

const props = defineProps<Props>()

const copyClicked = ref(false)

const copyToClipboard = () => {
    navigator.clipboard.writeText(props.toCopy || '')
    copyClicked.value = true
    setTimeout(() => {
        copyClicked.value = false
    }, 2000)
}
</script>
